import React from "react";

/*
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";

import Google from "../assets/icons/Google";
import SyncImage from "../assets/images/Sync.svg";
*/

import { withRouter } from "react-router-dom";
import { withUserContext } from "../contexts/User";
import { withStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";
import classnames from "classnames";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// core components
//import Header from "components/Header/Header.jsx";
//import HeaderLinks from "components/Header/HeaderLinks.jsx";
//import Footer from "components/Footer/Footer.jsx";
// components
import {
  GridContainer,
  GridItem,
  Card,
  CardHeader,
  CardBody,
  CustomInput,
  CardFooter,
  Button,
  Header,
  HeaderLinks
} from "../components";
// import Button from "components/CustomButtons/Button.jsx";
import loginPageStyle from "../assets/jss/material-kit-react/views/loginPage.jsx";
// import "assets/scss/material-kit-react.scss?v=1.4.0";

//import { SignUpLink } from "../components/Login/SignUp";
//import { PasswordForgetLink } from "../components/Login/PasswordForget";

import Firebase from "../services/Firebase";
import image from "../assets/img/wakanda-bg.jpg";
import logo from "../assets/img/calogo.png";

const styles = theme => ({
  root: {
    position: "absolute",
    height: "100%",
    width: "100%",
    margin: 0
  },
  image: {
    maxWidth: 300,
    display: "block",
    margin: "32px auto"
  },
  side: {
    height: "100%"
  },
  leftSide: {},
  rightSide: {
    backgroundColor: theme.palette.primary.dark
  },
  button: {
    margin: theme.spacing.unit,
    backgroundColor: "white",
    paddingRight: theme.spacing.unit * 2,
    minWidth: 200
  },
  buttonIcon: {
    fontSize: 32,
    marginLeft: theme.spacing.unit,
    paddingTop: 5
  },
  text: {
    color: theme.palette.primary.contrastText
  },
  ...loginPageStyle
});

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null
};

class Splash extends React.Component {
  constructor(props) {
    super(props);
    this.onSignIn = this.onSignIn.bind(this);
    this.state = {
      ...INITIAL_STATE,
      cardAnimaton: "cardHidden"
    };
    this._isMounted = false;
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this._isMounted = true;
    this._isMounted &&
      setTimeout(
        function() {
          this._isMounted && this.setState({ cardAnimaton: "" });
        }.bind(this),
        700
      );
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  onSignIn() {
    const { history, user } = this.props;
    if (user) {
      return history.push("/");
    }
    const provider = new Firebase.auth.GoogleAuthProvider();
    this._isMounted &&
      Firebase.auth()
        .signInWithPopup(provider)
        .then();
  }

  onSubmit = event => {
    const { email, password } = this.state;
    const { history } = this.props;

    Firebase.auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        return history.push("/");
      })
      .catch(error => {
        this.setState(byPropKey("error", error));
      });

    event.preventDefault();
  };

  render() {
    const { classes, ...rest } = this.props;
    const { email, password, error } = this.state;
    const isInvalid = password === "" || email === "";
    return (
      <div>
        <Header
          absolute
          color="transparent"
          brand="Conway Alphonso |"
          rightLinks={<HeaderLinks />}
          {...rest}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card className={classes[this.state.cardAnimaton]}>
                  <form className={classes.form} onSubmit={this.onSubmit}>
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h4 className={classes.defaultFont}>
                        <img src={logo} />
                      </h4>
                      <div className={classes.socialLine}>
                        <Button
                          justIcon
                          href="#ca"
                          target="_blank"
                          color="transparent"
                          onClick={e => e.preventDefault()}
                        >
                          <i className={"fas fa-code"} />
                        </Button>
                        <Button
                          justIcon
                          href="#ca"
                          target="_blank"
                          color="transparent"
                          onClick={e => e.preventDefault()}
                        >
                          <i className={"fas fa-mobile-alt"} />
                        </Button>
                        <Button
                          justIcon
                          href="#ca"
                          target="_blank"
                          color="transparent"
                          onClick={this.onSignIn}
                        >
                          <i className={"fas fa-laptop"} />
                        </Button>
                      </div>
                    </CardHeader>
                    <div
                      className={classnames([
                        classes.divider,
                        classes.defaultFont
                      ])}
                    >
                      <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                        <li>Web Services</li>
                        <li>App Development</li>
                        <li>B2B Marketing</li>
                        <li>Event Management Tools</li>
                        <li>Multi Media Marketing</li>
                        <li>t: 07808 069 982</li>
                        <li>e: info@conwayalphonso.com</li>
                      </ul>
                    </div>
                    {/*
                    <CardBody>
                      <h1>&nbsp;</h1>
                    </CardBody>
                    */}
                  </form>
                  <br />
                  <br />
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

Splash.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  history: PropTypes.object
};

export default withUserContext(withRouter(withStyles(styles)(Splash)));
