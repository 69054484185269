import React from "react";
import PropTypes from "prop-types";
//import classnames from "classnames";

import Button from "../Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import GridContainer from "../GridContainer";
import GridItem from "../GridItem";
import CustomInput from '../CustomInput';
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Category from "@material-ui/icons/Category";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import CategoryTable from "./CategoryTable.jsx";

import dashboardStyle from "../../assets/jss/material-kit-react/views/dashboardStyle.jsx";

import basicsStyle from "../../assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";
import Firebase, {
  firestore,
  doAddUserEcommerceCatData,
  editCategoryId,
  editSubCategory,
  userFS
} from "../../services/Firebase";

const prospectStyle = {
  ...dashboardStyle,
  ...basicsStyle,
  formControl: {
    width: "100%"
  },
  listimg: {
    width: 32,
    height: "auto"
  },
  deleteIcon: {
    position: "relative",
    top: 8,
    "&:hover": {
      cursor: "pointer"
    }
  },
  iconContainer: {
    flex: "0 0 auto",
    width: 48,
    height: 48,
    padding: 0
  },
  imageview: {
    maxWidth: 300
  },
  wrapListText: {
    wordWrap: "break-word"
  }
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});

class CategoryAdmin extends React.Component {
  state = {
    openDialog: false,
    openDialogEdit: false,
    openDialogSubcatEdit: false,
    category: {},
    categories: [],
    currentUser: this.props.currentUser || undefined,
    categorymsg: "",
    open: false,
    ready: false,
    search: "",
    page: 0,
    rowsPerPage: 5
  };

  constructor(props) {
    super(props);
    this.handleDialogOpen = this.handleDialogOpen.bind(this);
    this.handleToUpdate = this.handleToUpdate.bind(this);
    this.handDialogOpen2 = this.handDialogOpen2.bind(this);
    this.handDialogOpen3 = this.handDialogOpen3.bind(this);
  }

  componentDidMount() {
    /**
     *  this section was trying to work out between user and admin user. 
     *  The only user to see this component should be client site admin, so need to
     *  differentiate between those types of users. the 'uid' would be the client side id anyway
     *  so the only thing to worry about is the users role on this site.
     */
    /*
    const { authUser } = this.props;
    let uid;
    if (this.props.currentUser === undefined) uid = authUser.uid;
    else uid = this.props.currentUser.id;
    */

    let currentUser;
    let siteid;
    if (this.props.currentUser === undefined) {
      currentUser = Firebase.auth().currentUser;
      siteid = currentUser.uid
    } else {
      this.props.currentUser.uid = this.props.currentUser.id;
      currentUser = this.props.currentUser;
      siteid = currentUser.uid;
    }

    //categories
    this.unsubscribe2 = userFS(currentUser.uid).onSnapshot(
      snapshot => {
        const clientUser = snapshot.data();
        this.setState({
          ...snapshot.data(),
          clientUser: clientUser,
          loading: false
        });
        ('ADMIN' in clientUser.roles) ? 
          (this.setState({ siteid: window.localStorage.getItem('siteid') })) : 
          (this.setState({ siteid: currentUser.uid }))

        this.unsubscribe = firestore()
          .collection(`users/${this.state.siteid}/ecommerce/structures/categories`)
          .onSnapshot(querySnapshot => {
            let categories = [];
            querySnapshot.forEach(doc => {
              let category = doc.data();
              categories.push(
                Object.assign({ id: doc.id, client_id: this.state.siteid }, category)
              );
            });
            this.setState({
              categories: categories,
              categoriesSource: categories,
              ready: true
            });
          });
      }
    );
  }
  componentWillUnmount() {
    this.unsubscribe();
    this.unsubscribe2();
  }
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ open: false });
  };
  handleDialogOpen = scroll => () => {
    this.setState({
      openDialog: true,
      scroll,
      ...INITIAL_STATE
    });
  };

  handleDialogClose = () => {
    this.setState({ openDialog: false, ...INITIAL_STATE });
  };
  handleDialogEditClose = () => {
    this.setState({ openDialogEdit: false, ...INITIAL_STATE });
  };
  handleDialogSubcatEditClose = () => {
    this.setState({ openDialogSubcatEdit: false, ...INITIAL_STATE });
  };
  handDialogOpen2(scroll, category) {
    this.setState({
      openDialogEdit: true,
      scroll,
      category: category,
      editcategory: category.id
    });
  }
  handDialogOpen3(scroll, category, index) {
    this.setState({
      openDialogSubcatEdit: true,
      scroll,
      category: category,
      editcategory: category.id,
      subcatindex: index,
      subcatname: category.subcats[index].name
    });
  }
  handleToUpdate = eventForm => {
    // const { authUser } = this.props;

    let { category } = this.state;
    eventForm.preventDefault();

    let newcat = category;
    /**
     * 'uid' is really 'this.state.siteid' here the clients uid on godbody firestore database 
     */
    /*
    let uid;
    if (this.props.currentUser === undefined) uid = authUser.uid;
    else uid = this.props.currentUser.uid;
    */

    this.unregister = doAddUserEcommerceCatData(this.state.siteid, newcat).then(res => {
      this.setState({ open: true, categorymsg: res }); // snackbar message
    });

    this.handleDialogClose();
  };

  handleToEditUpdate = eventForm => {
    const { authUser } = this.props;

    let { category } = this.state;
    eventForm.preventDefault();
    /**
     *  'uid' si really 'this.state.siteid'
     */
    /*
    let uid;
    if (this.props.currentUser === undefined) uid = authUser.uid;
    else uid = this.props.currentUser.uid;
    */

    const delcat = category.id;
    category.id = this.state.editcategory;
    this.setState({ category: category });
    this.unregister = editCategoryId(this.state.siteid, category, delcat).then(res => {
      this.setState({ open: true, categorymsg: res }); // snackbar message
    });
    this.handleDialogEditClose();
  };

  handleToSubcatEditUpdate = eventForm => {
    const { authUser } = this.props;

    let { category, subcatindex, subcatname } = this.state;
    eventForm.preventDefault();
    /**
     *  'uid' si really 'this.state.siteid'
     */
    /*
    let uid;
    if (this.props.currentUser === undefined) uid = authUser.uid;
    else uid = this.props.currentUser.uid;
    */

    this.unregister = editSubCategory(
      this.state.siteid,
      category,
      subcatindex,
      subcatname
    ).then(res => {
      this.setState({ open: true, categorymsg: res }); // snackbar message
    });
    this.handleDialogSubcatEditClose();
  };

  render() {
    const { classes } = this.props;
    const { categories, siteid } = this.state;
    return (
      <div>
        <Button onClick={this.handleDialogOpen("paper")}>
          Add New Category
        </Button>

        <CategoryTable
          siteid={siteid}
          categories={categories}
          viewCategoryCTA={this.handDialogOpen2}
          viewSubCategoryCTA={this.handDialogOpen3}
        />

        <Dialog
          open={this.state.openDialog}
          onClose={this.handleDialogClose}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
        >
          <form className={classes.form} onSubmit={this.handleToUpdate}>
            <DialogTitle id="scroll-dialog-title">New Category</DialogTitle>
            <DialogContent>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <CustomInput
                    labelText="Category"
                    id="category"
                    name="category"
                    autoComplete="category"
                    autoFocus
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: this.state.product_id,
                      onChange: event =>
                        this.setState(
                          byPropKey("category", event.target.value)
                        ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Category className={classes.inputIconsColor} />
                        </InputAdornment>
                      )
                    }}
                  />
                </GridItem>
              </GridContainer>
              {/*error && <p>{error.message}</p>*/}
            </DialogContent>
            <DialogActions>
              <Button color="primary" type="submit">
                Save
              </Button>
              <Button onClick={this.handleDialogClose} color="primary">
                Discard
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        <Dialog
          open={this.state.openDialogEdit}
          onClose={this.handleDialogEditClose}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
        >
          <form className={classes.form} onSubmit={this.handleToEditUpdate}>
            <DialogTitle id="scroll-dialog-title">Edit Category</DialogTitle>
            <DialogContent>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <CustomInput
                    labelText="Category"
                    id="category"
                    name="category"
                    autoComplete="category"
                    autoFocus
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: this.state.editcategory,
                      onChange: event =>
                        this.setState(
                          byPropKey("editcategory", event.target.value)
                        ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Category className={classes.inputIconsColor} />
                        </InputAdornment>
                      )
                    }}
                  />
                </GridItem>
              </GridContainer>
              {/*error && <p>{error.message}</p>*/}
            </DialogContent>
            <DialogActions>
              <Button color="primary" type="submit">
                Save
              </Button>
              <Button onClick={this.handleDialogClose} color="primary">
                Discard
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Dialog
          open={this.state.openDialogSubcatEdit}
          onClose={this.handleDialogSubcatEditClose}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
        >
          <form
            className={classes.form}
            onSubmit={this.handleToSubcatEditUpdate}
          >
            <DialogTitle id="scroll-dialog-title">
              Edit Sub-Category
            </DialogTitle>
            <DialogContent>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <CustomInput
                    labelText="Category"
                    id="category"
                    name="category"
                    autoComplete="category"
                    autoFocus
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: this.state.subcatname,
                      onChange: event =>
                        this.setState(
                          byPropKey("subcatname", event.target.value)
                        ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Category className={classes.inputIconsColor} />
                        </InputAdornment>
                      )
                    }}
                  />
                </GridItem>
              </GridContainer>
              {/*error && <p>{error.message}</p>*/}
            </DialogContent>
            <DialogActions>
              <Button color="primary" type="submit">
                Save
              </Button>
              <Button onClick={this.handleDialogClose} color="primary">
                Discard
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <br />
        <br />

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.categorymsg}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    );
  }
}

const INITIAL_STATE = {
  error: null
};

CategoryAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
  //currentUser: PropTypes.object,
  //authUser: PropTypes.object.isRequired
};

export default withStyles(prospectStyle)(CategoryAdmin);
