import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Layout from "../components/Layout";
import Poll from "../components/Poll/Poll";

interface Props extends RouteComponentProps<void> {
  match:any,
  history:any,
  params?:any
}

function PollPage(props: Props) {
  
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddTodo = () => {
    setOpen(true);
  };

  //console.log('props', props);

  return (
    <Layout theme={null}>
      <Poll />
    </Layout>
  );
}


export default withRouter(PollPage);
