import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
// import bytes from "bytes";

import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

// @material-ui/icons
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import Button from '../Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from '@material-ui/core/DialogTitle'
import GridContainer from '../GridContainer'
import GridItem from '../GridItem'
import CustomInput from '../CustomInput';
import Category from '@material-ui/icons/Category'
import CloseIcon from '@material-ui/icons/Close'
import Snackbar from '@material-ui/core/Snackbar'
//import IconButton from "@material-ui/core/IconButton";
import InputAdornment from '@material-ui/core/InputAdornment'
import CategoryTableMenu from './CategoryTableMenu.jsx'
import SubCategoryTableMenu from './SubCategoryTableMenu.jsx'

import LinearProgress from '@material-ui/core/LinearProgress'

import { withStyles } from '@material-ui/core/styles'

import headerLinksStyle from '../../assets/jss/material-kit-react/components/headerLinksStyle.jsx'

// import Firebase, { firestore, usersFS } from "services/Firebase";
import { doAddUserEcommerceSubCatData } from '../../services/Firebase'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {},
  tableWrapper: {
    overflowX: 'auto',
  },
  tableRowName: {
    paddingRight: 0,
  },
  tableRowNameIcon: {
    paddingRight: 16,
    color: theme.palette.primary.dark,
  },
  tableRowNameText: {
    paddingTop: 5,
  },
  notFound: {
    paddingTop: 32,
  },
  notFoundImage: {
    width: 320,
    opacity: 0.5,
  },
  tableColumnWidth: {
    width: '25%',
    maxWidth: 200,
    overflow: 'hidden',
  },
  adminNavLink: {
    color: '#000',
    textDecoration: 'none',
    '&:hover': {
      color: '#000',
    },
  },
  ...headerLinksStyle,
})
const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
})

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
})

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0)
  }

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1)
  }

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1)
  }

  handleLastPageButtonClick = event => {
    this.props.onChangePage(event, Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1))
  }

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props

    return (
      <div className={classes.root}>
        <IconButton onClick={this.handleFirstPageButtonClick} disabled={page === 0} aria-label="First Page">
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={this.handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    )
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
}

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(TablePaginationActions)

class CategoryTable extends React.Component {
  state = {
    categories: this.props.categories,
    openDialog: false,
    open: false,
    ready: false,
    search: '',
    page: 0,
    rowsPerPage: 5,
  }

  constructor(props) {
    super(props)
    // we use this to make the card to appear after the page has been rendered
    this.viewCategory = this.viewCategory.bind(this)
    this.viewSubCategory = this.viewSubCategory.bind(this)
    this.handleDialogOpen = this.handleDialogOpen.bind(this)
    this.handleToUpdate = this.handleToUpdate.bind(this)
  }

  componentWillMount() {
    const { categories } = this.props
    // categories ? this.setState({ ready: true }) : null;
    if (categories) {
      this.setState({ ready: true })
    }
  }

  viewCategory(category) {
    this.props.viewCategoryCTA('paper', category)
  }

  viewSubCategory(category, index) {
    this.props.viewSubCategoryCTA('paper', category, index)
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ open: false })
  }

  handleDialogOpen = (scroll, category) => () => {
    this.setState({
      openDialog: true,
      category: category,
      scroll,
      ...INITIAL_STATE,
    })
  }
  handleDialogClose = () => {
    this.setState({ openDialog: false, ...INITIAL_STATE })
  }
  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value })
  }
  handleToUpdate = eventForm => {
    const { siteid } = this.props

    let { subcategory, category } = this.state
    eventForm.preventDefault()

    let newsubcat = subcategory
    /**
     * 'uid' is really 'siteid'
     */
    /*
    let uid
    if (this.props.currentUser === undefined) uid = authUser.uid
    else uid = this.props.currentUser.uid
    */

    this.unregister = doAddUserEcommerceSubCatData(siteid, category, newsubcat).then(res => {
      this.setState({ open: true, categorymsg: res }) // snackbar message
    })

    this.handleDialogClose()
  }
  render() {
    const { classes, categories } = this.props
    const { rowsPerPage, page } = this.state
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, categories.length - page * rowsPerPage)

    return this.state.ready ? (
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <Hidden xsDown smDown>
                <TableCell>Sub-Categories</TableCell>
              </Hidden>
              <Hidden xsDown smDown>
                <TableCell />
              </Hidden>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => {
              return (
                <TableRow key={item.id}>
                  <TableCell className={classnames([classes.tableRowName, classes.tableColumnWidth])}>
                    <Grid container direction={'row'}>
                      <Grid item className={classes.tableRowNameText}>
                        {item.id}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <Hidden xsDown smDown>
                    <TableCell className={classnames([classes.tableRowName, classes.tableColumnWidth])}>
                      <Table className={classes.table}>
                        <TableBody>
                          {item.subcats
                            ? item.subcats.map((subcat, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell className={classnames([classes.tableRowName, classes.tableColumnWidth])}>
                                      {subcat.name}
                                    </TableCell>

                                    <TableCell>
                                      <SubCategoryTableMenu
                                        category={item}
                                        subcatindex={index}
                                        viewSubCategory={this.viewSubCategory}
                                      />
                                    </TableCell>
                                  </TableRow>
                                )
                              })
                            : null}
                        </TableBody>
                      </Table>
                    </TableCell>
                  </Hidden>
                  <Hidden xsDown>
                    <TableCell>
                      <Button onClick={this.handleDialogOpen('paper', item.id)}>Add New Sub-Category</Button>
                    </TableCell>
                  </Hidden>
                  <TableCell>
                    <CategoryTableMenu category={item} viewCategory={this.viewCategory} />
                  </TableCell>
                </TableRow>
              )
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 48 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={3}
                count={categories.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  native: false,
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActionsWrapped}
              />
            </TableRow>
          </TableFooter>
        </Table>

        <Dialog
          open={this.state.openDialog}
          onClose={this.handleDialogClose}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
        >
          <form className={classes.form} onSubmit={this.handleToUpdate}>
            <DialogTitle id="scroll-dialog-title">New Sub Category</DialogTitle>
            <DialogContent>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <CustomInput
                    labelText="Sub-Category"
                    id="subcategory"
                    name="subcategory"
                    autoComplete="subcategory"
                    autoFocus
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'text',
                      onChange: event => this.setState(byPropKey('subcategory', event.target.value)),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Category className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
              </GridContainer>
              {/*error && <p>{error.message}</p>*/}
            </DialogContent>
            <DialogActions>
              <Button color="primary" type="submit">
                Save
              </Button>
              <Button onClick={this.handleDialogClose} color="primary">
                Discard
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <br />
        <br />

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.categorymsg}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    ) : (
      <LinearProgress />
    )
  }
}

const INITIAL_STATE = {
  error: null,
}

CategoryTable.propTypes = {
  classes: PropTypes.object.isRequired,
  categories: PropTypes.array,
  viewCategoryCTA: PropTypes.func,
  viewSubCategoryCTA: PropTypes.func,
  currentUser: PropTypes.object,
}

export default withStyles(styles)(CategoryTable)
