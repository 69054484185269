import * as React from "react";

// @material-ui/core components
import { Theme, makeStyles } from '@material-ui/core';
import Grid from "@material-ui/core/Grid";

interface Props {
  children: any,
  className?: any,
  history: any,
  location: any,
  match: any,
  xs:any,
  sm:any,
  md:any
}

function GridItem(props: Props) {
  const classes = useStyles();
  const { children, className, ...rest } = props;
  return (
    <Grid item {...rest} className={classes.grid + " " + className}>
      {children}
    </Grid>
  );
}

// @ts-ignore "fuck CSS typings"
const useStyles = makeStyles((theme: Theme) => ({
  root: {

  },
  grid: {
    position: "relative",
    width: "100%",
    minHeight: "1px",
    paddingRight: "15px",
    paddingLeft: "15px",
    flexBasis: "auto"
  }
}));

export default GridItem as React.ComponentType<any>;
