import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
// import bytes from "bytes";

import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import AdminProductTableMenu from "./AdminProductTableMenu.jsx";

import LinearProgress from "@material-ui/core/LinearProgress";

import { withStyles } from "@material-ui/core/styles";

import headerLinksStyle from '../../assets/jss/material-kit-react/components/headerLinksStyle.jsx'

// import Firebase, { firestore, usersFS } from "services/Firebase";
// import { firestore } from "services/Firebase";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  table: {},
  tableWrapper: {
    overflowX: "auto"
  },
  tableRowName: {
    paddingRight: 0
  },
  tableRowNameIcon: {
    paddingRight: 16,
    color: theme.palette.primary.dark
  },
  tableRowNameText: {
    paddingTop: 5
  },
  notFound: {
    paddingTop: 32
  },
  notFoundImage: {
    width: 320,
    opacity: 0.5
  },
  tableColumnWidth: {
    width: "25%",
    maxWidth: 200,
    overflow: "hidden"
  },
  adminNavLink: {
    color: "#000",
    textDecoration: "none",
    "&:hover": {
      color: "#000"
    }
  },
  ...headerLinksStyle
});
const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true
})(TablePaginationActions);

class AdminProductTable extends React.Component {
  state = {
    products: this.props.products,
    ready: false,
    search: "",
    page: 0,
    rowsPerPage: 5
  };

  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.viewProduct = this.viewProduct.bind(this);
  }

  componentWillMount() {
    const { products } = this.props;
    // products ? this.setState({ ready: true }) : null;
    if(products){
      this.setState({ ready: true });
    }
  }

  viewProduct(product) {
    this.props.viewProductCTA("paper", product);
  }
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };
  render() {
    const { classes, products } = this.props;
    const { rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, products.length - page * rowsPerPage);

    return this.state.ready ? (
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <Hidden xsDown smDown>
                <TableCell>Description</TableCell>
              </Hidden>
              <Hidden xsDown smDown>
                <TableCell>Price</TableCell>
              </Hidden>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {products
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(item => {
                return (
                  <TableRow key={item.id}>
                    <TableCell
                      className={classnames([
                        classes.tableRowName,
                        classes.tableColumnWidth
                      ])}
                    >
                      <Grid container direction={"row"}>
                        <Grid item className={classes.tableRowNameText}>
                          {item.product_name}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <Hidden xsDown smDown>
                      <TableCell
                        className={classnames([
                          classes.tableRowName,
                          classes.tableColumnWidth
                        ])}
                      >
                        {item.product_desc}
                      </TableCell>
                    </Hidden>
                    <Hidden xsDown>
                      <TableCell
                        className={classnames([
                          classes.tableRowName,
                          classes.tableColumnWidth
                        ])}
                      >
                        £{item.price}
                      </TableCell>
                    </Hidden>
                    <TableCell>
                      <AdminProductTableMenu
                        product={item}
                        viewProduct={this.viewProduct}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 48 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={3}
                count={products.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  native: false
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActionsWrapped}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    ) : (
      <LinearProgress />
    );
  }
}
AdminProductTable.propTypes = {
  classes: PropTypes.object.isRequired,
  products: PropTypes.array,
  viewProductCTA: PropTypes.func
};

export default withStyles(styles)(AdminProductTable);
