import React, { Component } from "react";
import ReactAvatarEditor from 'react-avatar-editor';
import Preview from '../Preview.jsx';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import update from "immutability-helper";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import ReorderListItem from "./ReorderListItem";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ConfirmationDialog from "../ConfirmationDialog";
import dashboardStyle from "../../assets/jss/material-kit-react/views/dashboardStyle.jsx";
import basicsStyle from "../../assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";
import avatarimage from "../avatar.jpg";

import Firebase, {
  gcfn,
  siteid,
  uploadEcommerceCroppedImage,
  getFileURL,
  checkEcommerceFile,
  uploadEcommerceFile,
  overwriteEcommerceFile
} from '../../services/Firebase'

const style = {
  ...dashboardStyle,
  ...basicsStyle,
  formControl: {
    width: "100%"
  },
  listimg: {
    width: 32,
    height: "auto"
  },
  deleteIcon: {
    position: "relative",
    top: 8,
    "&:hover": {
      cursor: "pointer"
    }
  },
  iconContainer: {
    flex: "0 0 auto",
    width: 48,
    height: 48,
    padding: 0
  },
  imageview: {
    maxWidth: 300
  },
  wrapListText: {
    wordWrap: "break-word",
    overflow: "hidden",
    marginLeft: 5,
    marginRight: 25,
  }
};

class ReorderList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: false,
      openConfirmation: false,
      openImage: false,
      currentImage: null,
      items:
        props.product.imageList &&
        props.product.imageList.map(item => ({
          id: item.id,
          live: item.live,
          name: item.name,
          url: item.url
        })),
        image: avatarimage,
        allowZoomOut: false,
        position: { x: 0.5, y: 0.5 },
        scale: 1,
        rotate: 0,
        borderRadius: 0,
        preview: null,
        width: 400,
        height: 400,
    };

    // this.handleOnClick = this.handleOnClick.bind(this);
    this.moveListItem = this.moveListItem.bind(this);
    this.handleOnToggle = this.handleOnToggle.bind(this);
  }

  // needs some kind of timer function to eliminate the multiple calling tht happens here
  componentDidUpdate(prevProps) {
    if (this.props.product !== prevProps.product) {
      this.setState({ items: this.props.product.imageList, productId: this.props.product.id });
    }
  }

  //handleOnClick(item) {
  // You may pass handle on click event to props
  // console.log(item);
  //}

  moveListItem(dragIndex, hoverIndex) {
    const { items } = this.state;
    const dragitem = items[dragIndex];

    this.setState(
      update(this.state, {
        items: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragitem]]
        }
      })
    );
    // You may pass the state to props
    this.props.onImageList(this.state);
  }

  handleOnToggle(index) {
    let currentItems = this.state.items;
    currentItems[index].live = !currentItems[index].live;
    this.setState({ items: currentItems, ...this.state });
    //this.props.onImageToggle(id);
    this.props.onImageList(this.state);
  }

  deleteImage = index => () => {
    let { items } = this.state;
    items.splice(index, 1);
    this.setState({ items: items });
  };

  openImageDialog = (image, filename) => {
    // get image from firebase function so it bypasses cross domain issues
    // 1 try sending image url to node server and then load image a 64blob thingy
    const chatboutImage = gcfn.httpsCallable('chatboutImage');
    let imageToCrop = null;
    chatboutImage({filename: filename, siteid: siteid}).then((result) => {
      imageToCrop = `data:image/jpg;base64,${result.data.encodedimage}`;
      this.setState({ openImage: true, currentImage: filename, image: imageToCrop });
    }).catch((error) => {
      // Getting the Error details.
      // var code = error.code;
      // var message = error.message;
      // var details = error.details;
      console.log(error);
    });
  };

  handleImageClose = () => {
    this.setState({ openImage: false });
  };

  deleteImage = index => {
    let { items } = this.state;
    items.splice(index, 1);
    this.setState({ items: items });
    this.props.onImageList(this.state);
  };

  onClickSaveCrop = () => {
    if (this.editor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      //const canvas = this.editor.getImage()

      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      //const canvasScaled = this.editor.getImageScaledToCanvas()
      //console.log('canvasScaled', canvasScaled);

      const image = this.editor.getImageScaledToCanvas().toDataURL('image/png');
      const stringLength = image.split(',')[1].length;
      const sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
      const sizeInKb = sizeInBytes/1000;
      const filename = 'cropped_' + this.state.currentImage.replace(".jpg", ".png");
      filename.replace(".jpeg", ".png");

      uploadEcommerceCroppedImage(image.split(',')[1], filename, sizeInKb).then(res => {
        this.props.imageFile(filename, res[1].id); // send this back to EcoAdmin to be placed in image list ready to be saved
        this.setState({openImage: false});
      });
    }
  }

  handleConfirm = confirmed => {
    this.setState({ openConfirmation: false });
    if (confirmed) {
      overwriteEcommerceFile(this.state.file)
        .then(res => {
          // console.log("this is the overwrite file to send back to imageFile/handleImages", this.state.file);
          const id = res[1].id;
          this.props.imageFile(this.state.file.name, id);
          this.handleClose();
        })
        .catch(() => {
          this.handleClose();
        });
    } else {
      this.handleClose();
    }
  };
  
//////////////////
  handleNewImage = e => {
    this.setState({ image: e.target.files[0] })
  }

  handleSave = data => {
    //let img = new Image();
    //let timestamp = new Date().getTime();
    //img.setAttribute('crossOrigin', 'anonymous');
    //img.src = this.currentImage + '?' + timestamp;

    let img = this.editor.getImageScaledToCanvas().toDataURL();
    const rect = this.editor.getCroppingRect()

    this.setState({
      preview: {
        img,
        rect,
        scale: this.state.scale,
        width: this.state.width,
        height: this.state.height,
        borderRadius: this.state.borderRadius,
      },
    })
  }

  handleScale = e => {
    const scale = parseFloat(e.target.value)
    this.setState({ scale })
  }

  handleAllowZoomOut = ({ target: { checked: allowZoomOut } }) => {
    this.setState({ allowZoomOut })
  }

  rotateLeft = e => {
    e.preventDefault()

    this.setState({
      rotate: this.state.rotate - 90,
    })
  }

  rotateRight = e => {
    e.preventDefault()
    this.setState({
      rotate: this.state.rotate + 90,
    })
  }

  handleBorderRadius = e => {
    const borderRadius = parseInt(e.target.value)
    this.setState({ borderRadius })
  }

  handleXPosition = e => {
    const x = parseFloat(e.target.value)
    this.setState({ position: { ...this.state.position, x } })
  }

  handleYPosition = e => {
    const y = parseFloat(e.target.value)
    this.setState({ position: { ...this.state.position, y } })
  }

  handleWidth = e => {
    const width = parseInt(e.target.value)
    this.setState({ width })
  }

  handleHeight = e => {
    const height = parseInt(e.target.value)
    this.setState({ height })
  }

  logCallback(e) {
    // eslint-disable-next-line
    console.log('callback', e)
  }

  setEditorRef = editor => {
    if (editor) this.editor = editor
  }

  handlePositionChange = position => {
    this.setState({ position })
  }

  handleDrop = acceptedFiles => {
    this.setState({ image: acceptedFiles[0] })
  }
////////////////////


  render() {
    const { classes, id } = this.props;
    const { items } = this.state;
    //('reorderlist items', items);
    return (
      <div>
        <List dense className={classes.root}>
          {items.map((item, i) => (
            <ReorderListItem
              key={item.id}
              //handleOnClick={() => this.handleOnClick(item)}
              index={i}
              id={item.id}
              text={item.name}
              live={item.live}
              url={item.url}
              classes={classes}
              liveUpdate={this.handleOnToggle}
              moveListItem={this.moveListItem}
              deleteImage={this.deleteImage}
              openImageDialog={this.openImageDialog}
            />
          ))}
        </List>
        <Dialog
          open={this.state.openImage}
          onClose={this.handleImageClose}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
        >

          <DialogTitle>Product Image</DialogTitle>
          <DialogContent>

          {/*<img className={classes.imageview} src={this.state.image} />*/}
          <div>


              <div>
                <ReactAvatarEditor
                  ref={this.setEditorRef}
                  scale={parseFloat(this.state.scale)}
                  width={this.state.width}
                  height={this.state.height}
                  position={this.state.position}
                  onPositionChange={this.handlePositionChange}
                  rotate={parseFloat(this.state.rotate)}
                  borderRadius={this.state.width / (100 / this.state.borderRadius)}
                  onLoadFailure={this.logCallback.bind(this, 'onLoadFailed')}
                  onLoadSuccess={this.logCallback.bind(this, 'onLoadSuccess')}
                  onImageReady={this.logCallback.bind(this, 'onImageReady')}
                  image={this.state.image}
                  className="editor-canvas"
                />
              </div>

            <br />
            New File:
            <input name="newImage" type="file" onChange={this.handleNewImage} />
            <br />
            Zoom:
            <input
              name="scale"
              type="range"
              onChange={this.handleScale}
              min={this.state.allowZoomOut ? '0.1' : '1'}
              max="2"
              step="0.01"
              defaultValue="1"
            />
            <br />
            {'Allow Scale < 1'}
            <input
              name="allowZoomOut"
              type="checkbox"
              onChange={this.handleAllowZoomOut}
              checked={this.state.allowZoomOut}
            />
            <br />
            Border radius:
            <input
              name="scale"
              type="range"
              onChange={this.handleBorderRadius}
              min="0"
              max="50"
              step="1"
              defaultValue="0"
            />
            <br />
            Avatar Width:
            <input
              name="width"
              type="number"
              onChange={this.handleWidth}
              min="50"
              max="400"
              step="10"
              value={this.state.width}
            />
            <br />
            Avatar Height:
            <input
              name="height"
              type="number"
              onChange={this.handleHeight}
              min="50"
              max="400"
              step="10"
              value={this.state.height}
            />
            <br />
            X Position:
            <input
              name="scale"
              type="range"
              onChange={this.handleXPosition}
              min="0"
              max="1"
              step="0.01"
              value={this.state.position.x}
            />
            <br />
            Y Position:
            <input
              name="scale"
              type="range"
              onChange={this.handleYPosition}
              min="0"
              max="1"
              step="0.01"
              value={this.state.position.y}
            />
            <br />
            Rotate:
            <button onClick={this.rotateLeft}>Left</button>
            <button onClick={this.rotateRight}>Right</button>
            <br />
            <br />
            <input type="button" onClick={this.handleSave} value="Preview" />
            <br />
            {!!this.state.preview && (
              <img
                id="thecroppedimage"
                src={this.state.preview.img}
                style={{
                  backgroundColor: '#fff',
                  borderRadius: `${(Math.min(
                    this.state.preview.height,
                    this.state.preview.width
                  ) +
                    10) *
                    (this.state.preview.borderRadius / 2 / 100)}px`,
                }}
              />
            )}
            {/*!!this.state.preview && (
              <Preview
                width={
                  this.state.preview.scale < 1
                    ? this.state.preview.width
                    : this.state.preview.height * 478 / 270
                }
                height={this.state.preview.height}
                image={this.state.preview.img}
                rect={this.state.preview.rect}
              />
              )*/}
          </div>

          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.onClickSaveCrop}
              color="primary"
            >
              Crop
            </Button>
          </DialogActions>
        </Dialog>
        <ConfirmationDialog
          open={this.state.openConfirmation}
          content={`This file exists, do you want to overwrite it?`}
          onClose={this.handleConfirm}
        />
      </div>
    );
  }
}

ReorderList.propTypes = {
  classes: PropTypes.object,
  items: PropTypes.object,
  onImageList: PropTypes.func
};

const mapStateToProps = state => ({
  imageList: state.imageListState.imageList,
  imageToggle: state.imageToggleState.imageToggle
});

const mapDispatchToProps = dispatch => ({
  onImageList: imageList => dispatch({ type: "IMAGE_LIST_SET", imageList }),
  onImageToggle: imageToggle => dispatch({ type: "IMAGE_TOGGLE", imageToggle })
});

// const authCondition = authUser => !!authUser && authUser.role === "ADMIN";

export default compose(
  //withAuthorization(authCondition),
  withStyles(style),
  DragDropContext(HTML5Backend),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ReorderList);
