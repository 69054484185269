import * as React from "react";
import { RouteComponentProps, Link, Redirect } from "react-router-dom";
import classnames from "classnames";

// material ui components
import Menu from "@material-ui/core/Menu";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import { Theme, makeStyles } from '@material-ui/core';
import { Person } from "@material-ui/icons";

// components
import {
  ConfirmationDialog,
  Button
} from "../components/";

// services
import Firebase, { getCurrentUser, useSession } from "../services/Firebase";

// styles
import headerLinksStyle from "../assets/jss/material-kit-react/components/headerLinksStyle";
import { defaultFont } from "../assets/jss/material-kit-react";

// @ts-ignore "fuck CSS typings"
const useStyles = makeStyles((theme: Theme) => ({
  list: {
    ...defaultFont,
    fontSize: "14px",
    margin: 0,
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: "inherit"
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:after": {
        width: "calc(100% - 30px)",
        content: '""',
        display: "block",
        height: "1px",
        marginLeft: "15px",
        backgroundColor: "#e5e5e5"
      }
    }
  },
  listItemText: {
    padding: "0 !important"
  },
  navLink: {
    color: "inherit",
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: "inherit",
      background: "rgba(200, 200, 200, 0.2)"
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start"
      }
    }
  },
  ...headerLinksStyle,
  root: {
    flexGrow: 1
  },
  flex: {
    flex: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
}));

export interface Props extends RouteComponentProps<void> {
  user?: any,
  error: any,
  location: any,
  match: any
}

function LayoutMenu(props: Props) {
  const classes = useStyles();
  const user = useSession();
  const { location } = props;
  const [history, setHistory] = React.useState(props.history);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [open, setOpen] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [operation, setOperation] = React.useState("");
  const [IsAdmin, setIsAdmin] = React.useState(false);

  React.useEffect(() => {
    // code to run on component mount
    let isSubscribed = true
    if (!!user && user.uid)
      getCurrentUser(user.uid).then(res => {
        if (isSubscribed) {
        const authUser = res;
        // how to check for admin user
        if (authUser && !!authUser.roles && !!authUser.roles.ADMIN) {
          setIsAdmin(true)
        }
        // this.setState({ ...res });
      }
      });
      return () => isSubscribed = false as any;
  }, [IsAdmin])

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSettings = () => {
    setAnchorEl(null);
    const { history } = props;
    history.push("/settings");
  };

  /*
  const handleAccounts = () => {
    setAnchorEl(null);
    const { history } = props;
    history.push("/accounts");
  };
  */

  const handleSignOut = () => {
    setAnchorEl(null);
    setOpenConfirmation(true);
    setOperation("signOut");
  };

  const handleConfirm = (confirmed: any) => {
    setOpenConfirmation(false);
    if (confirmed) {
      if (operation === "signOut") {
        Firebase.auth()
          .signOut()
          .then(() => {
            // history.push("/login"); 
              return <Redirect to="/login" />;
          });
      }
    }
  };


  const open: boolean = Boolean(anchorEl);
  let userLetter;
  let photoURL;

  if (user && user.photoURL === null) {
    userLetter = user.email ? user.email.charAt(0) : null;
    photoURL = ""
  }
  photoURL = (user && user.photoURL === null) ? "" : ((user && user.photoURL) ? user.photoURL : "");

  return (
    <div>
      {user && user.email ? (
        <div style={{padding: "7px"}}>
          <IconButton
            aria-owns={open ? "menu-appbar" : ""}
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Avatar alt={user.email} src={photoURL}>
              {userLetter}
            </Avatar>
          </IconButton>
        </div>
      ) : (
        <Link to={"/login"} target="_self" className={classes.navLink}>
          <Button
            color="transparent"
            
            className={classnames([classes.navLink])}
          >
            <Person className={classes.icons} /> Login
          </Button>
        </Link>
        )}
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem>{user ? (user.displayName || user.email) : null}</MenuItem>
        <Divider />
        <MenuItem>
          <Link to={"/home"}>Home</Link>
        </MenuItem>
        <Divider />
        <MenuItem>
          <Link to={"/account"}>Account</Link>
        </MenuItem>
        <Divider />
          <MenuItem onClick={handleSettings}>Settings</MenuItem>
        {IsAdmin ? (
        <div>
          <Divider />
          <MenuItem>
            <Link to={"/admin"}>Admin</Link>
          </MenuItem>
        </div>
        ) : (
          null
        )}
        <Divider />
        <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
      </Menu>
      <ConfirmationDialog
        open={openConfirmation}
        content={`Are you sure you want to logout ?`}
        onClose={handleConfirm}
        match={null}
        history={history}
        location={location}
      />
    </div>
  );
  // }
}

export default LayoutMenu;
