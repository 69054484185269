import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Theme, makeStyles } from '@material-ui/core';

import Layout from "../components/Layout";
// import FilesTable from "../components/FilesTable";
// import HomeTabs from "../components/HomeTabs/HomeTabs.jsx";
import HomePage from "./HomePage";

import loginPageStyle from "../assets/jss/material-kit-react/views/loginPage";
import image from "../assets/img/wakanda-bg.jpg";

// @ts-ignore "fuck CSS typings"
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 20,
    [theme.breakpoints.down("md")]: {
      paddingTop: 50,
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  ...loginPageStyle
}));

interface Props extends RouteComponentProps<void> {
  rest: any,
  history: any
}

function Home(props: Props) {
  const { history } = props;
  const classes = useStyles() as any;
  return (
    <div>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <HomePage />
      </div>
    </div>
  );
  // }
}

export default Home;
