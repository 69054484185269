import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

// import classnames from "classnames";
import PropTypes from 'prop-types'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Snackbar from '@material-ui/core/Snackbar'
import Input from '@material-ui/core/Input'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from '@material-ui/core/DialogTitle'


// @material-ui/icons
import People from '@material-ui/icons/People'
import Work from '@material-ui/icons/Work'
import Domain from '@material-ui/icons/Domain'
import CloseIcon from '@material-ui/icons/Close'

import GridContainer from '../GridContainer'
import GridItem from '../GridItem'
import Button from '../Button'
import CardBody from '../CardBody'
import CardFooter from '../CardFooter'
import CustomInput from '../CustomInput'
import AdminProductTable from '../AdminProductTable/AdminProductTable'
import CategoryAdmin from '../AdminProductTable/CategoryAdmin'
import ReorderList from '../ReorderList/ReorderList'
import CustomFieldList from '../CustomFieldList/CustomFieldList'

import EcommerceUploadDialog from './EcommerceUploadDialog.jsx'
import EcommerceCustomFieldDialog from './EcommerceCustomFieldDialog.jsx'

import dashboardStyle from '../../assets/jss/material-kit-react/views/dashboardStyle.jsx'
import basicsStyle from '../../assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx'

import Firebase, {
  doAddUserEcommerceData,
  doUpdateUserEcommerceData,
  getFileURL,
  firestore,
  userFS,
} from '../../services/Firebase'

const prospectStyle = {
  ...dashboardStyle,
  ...basicsStyle,
  formControl: {
    width: '100%',
  },
  listimg: {
    width: 32,
    height: 'auto',
  },
  deleteIcon: {
    position: 'relative',
    top: 8,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  iconContainer: {
    flex: '0 0 auto',
    width: 48,
    height: 48,
    padding: 0,
  },
  imageview: {
    maxWidth: 300,
  },
  wrapListText: {
    wordWrap: 'break-word',
  },
}

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
})

class EcommerceAdmin extends React.Component {
  constructor(props) {
    super(props)
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      ...INITIAL_STATE,
      cardAnimaton: 'cardHidden',
      checked: [],
      selectedEnabled: 'b',
      checkedA: true,
      checkedB: false,
      labelWidth: 0,
      imgChecked: [1],
      imageLimit: false,
      open: false,
      openUpload: false,
      openCustomField: false,
      openDialog: false,
      openImage: false,
      products: [],
      currentImage: {},
      currentUser: props.currentUser || undefined,
      categories: [],
      subcategories: [],
    }
    this.handleCatChange = this.handleCatChange.bind(this)
    this.handleToUpdate = this.handleToUpdate.bind(this)
    this.handleDialogOpen = this.handleDialogOpen.bind(this)
    this.handDialogOpen2 = this.handDialogOpen2.bind(this)
    this.handleImages = this.handleImages.bind(this)
    this.handleCustomFields = this.handleCustomFields.bind(this)
    this.handleCustomFieldsList = this.handleCustomFieldsList.bind(this)
  }

  componentDidMount() {
    // const currentUser = Firebase.auth().currentUser;
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function () {
        this.setState({ cardAnimaton: '' })
      }.bind(this),
      700
    )
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.newImageList !== prevProps.newImageList) {
      this.setState({ imageList: this.props.newImageList.items })
    }
    if (this.props.products !== prevProps.products) {
      this.setState({ products: this.props.products })
    }
    if (this.props.categories !== prevProps.categories) {
      // this.setState({ products: this.props.products });
      setTimeout(() => {
        // needs a delay for database to catch up, may need to adjust here if still not viable in production
        this.loadData()
      }, 1000)
    }
  }

  componentWillUnmount() {
    this.unsubscribe()
    this.unsubscribe2()
    //this.unsubscribe3()
    this.unsubscribe4()
  }

  loadData() {

    let currentUser;
    let siteid;
    if (this.props.currentUser === undefined) {
      currentUser = Firebase.auth().currentUser;
      siteid = currentUser.uid
    } else {
      this.props.currentUser.uid = this.props.currentUser.id;
      currentUser = this.props.currentUser;
      siteid = currentUser.uid;
    }

    this.unsubscribe4 = userFS(currentUser.uid).onSnapshot(
      snapshot => {
        const clientUser = snapshot.data();
        this.setState({
          ...snapshot.data(),
          clientUser: clientUser,
          loading: false
        });
        ('ADMIN' in clientUser.roles) ? 
          (this.setState({siteid: window.localStorage.getItem('siteid')})) : 
          (this.setState({siteid: currentUser.uid}))
        const db = firestore()
        this.unsubscribe3 = new Promise(resolve => {
          Promise.all([
            (this.unsubscribe = db
              .collection(`users/${this.state.siteid}/ecommerce/structures/categories`) //categories
              .onSnapshot(querySnapshot => {
                //console.log('you getting some?', this.state.siteid, querySnapshot)
                let categories = []
                querySnapshot.forEach(doc => {
                  let category = doc.data()
                  categories.push(Object.assign({ id: doc.id, client_id: siteid }, category))
                })
                this.setState({
                  categories: categories,
                  categoriesSource: categories,
                  ready: true,
                })
              })),
            (this.unsubscribe2 = db
              .collection(`users/${this.state.siteid}/ecommerce`) //products
              .orderBy('product_id', 'desc')
              .onSnapshot(querySnapshot => {
                let products = []
                querySnapshot.forEach(doc => {
                  let product = doc.data()
                  // replace category name with current category index
                  const { categories } = this.state
                  let catindex = categories.findIndex(category => category.id === product.category)
                  product.category = catindex

                  // replace subcategory with current subcategory index
                  if (catindex !== -1) {
                    let subcatindex = -1
                    subcatindex = categories[catindex].subcats.findIndex(subcat => {
                      return subcat.name === product.subcategory
                    })
                    product.subcategory = subcatindex
                  }

                  products.push(Object.assign({ id: doc.id, client_id: this.state.siteid }, product))
                })
                this.setState({
                  products: products,
                  productsSource: products,
                  //  ready: true
                })
              })),
          ]).then(result => {
            resolve(result)
          })
        })

      }
    )
  }

  handleCatChange = event => {
    // load subcat for this cat
    this.setState({ [event.target.name]: event.target.value })
    let subcat
    if (this.state.categories[event.target.value]) subcat = this.state.categories[event.target.value].subcats
    else subcat = []

    this.setState({
      subcategories: subcat,
    })
  }

  handleSubCatChange = event => {
    // load subcat for this cat
    this.setState({ [event.target.name]: event.target.value })
  }

  handleChangeEnabled(event) {
    this.setState({ selectedEnabled: event.target.value })
  }

  handleToggle(value) {
    const { checked } = this.state
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    this.setState({
      checked: newChecked,
    })
  }
  handleImageToggle = value => () => {
    const { imgChecked } = this.state
    const currentIndex = imgChecked.indexOf(value)
    const newChecked = [...imgChecked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    this.setState({
      imgChecked: newChecked,
    })
  }
  handleDeleteImage = (file, index) => () => {
    let { imageList } = this.state
    imageList.splice(index, 1)
    this.setState({ imageList: imageList })
    /*
    deleteEcommerceImage(file).then(() => {
      console.log("delete image", this.state.imageList[index]);
      let { imageList } = this.state;
      const newImageList = imageList.splice(index, 1);
      this.setState({ imageList: imageList });
    });
    */
  }
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ open: false })
  }

  handleUpload = () => {
    this.setState({ openUpload: false })
  }

  handleCustomFieldUpdate = () => {
    this.setState({ openCustomField: false })
  }

  handleDialogOpen = scroll => () => {
    this.setState({
      openDialog: true,
      scroll: scroll,
      ...INITIAL_STATE,
    })
  }

  handleDialogClose = () => {
    this.setState({ openDialog: false, ...INITIAL_STATE })
  }

  handleImageClose = () => {
    this.setState({ openImage: false })
  }

  handleToUpdate = eventForm => {
    const { authUser } = this.props

    let {
      id,
      product_id,
      product_name,
      product_desc,
      blurb,
      price,
      imageList,
      imgChecked,
      product_image1,
      product_image2,
      product_image3,
      category,
      subcategory,
      tags,
      customFieldList,
      siteid
    } = this.state
    eventForm.preventDefault()

    if (typeof this.state.categories[category] === 'undefined') {
      category = ''
    } else {
      category = this.state.categories[category].id //change category index to actual name
    }

    if (typeof this.state.subcategories[subcategory] === 'undefined') {
      subcategory = ''
    } else {
      subcategory = this.state.subcategories[subcategory].name //change subcategory index to actual name
    }

    /**
     * modify the next for any checkboxes in the ecommerce form
     */
    /*
    checked.includes(1)
      ? (already_registered = true)
      : (already_registered = false);
    checked.includes(2) ? (register_for_me = true) : (register_for_me = false);
    checked.includes(3) ? (register_anon = true) : (register_anon = false);
    */

    let data = {
      product_id,
      product_name,
      product_desc,
      blurb,
      price,
      imageList,
      imgChecked,
      product_image1,
      product_image2,
      product_image3,
      category,
      subcategory,
      tags,
      customFieldList,
    }

    /**
     * 'uid is really siteid
     */
    /*
    let uid;
    if (this.props.currentUser) uid = this.props.currentUser.uid;
    else uid = authUser.uid;
    */

    if (id === undefined) {
      this.unregister = doAddUserEcommerceData(siteid, data).then(result => {
        //console.log('new product_id', result);
      })
      this.setState({ open: true, ...INITIAL_STATE })
    } else {
      this.unregister = doUpdateUserEcommerceData(siteid, id, data)
      this.setState({ open: true })
    }
    this.handleDialogClose()
  }

  handDialogOpen2(scroll, product) {
    // maybe set the subcat here now that we have the product
    let subcat
    if (this.state.categories[product.category]) subcat = this.state.categories[product.category].subcats
    else subcat = []

    this.setState({
      openDialog: true,
      scroll,
      subcategories: subcat,
      ...product,
    })
  }

  handleImages(imageFile, id) {
    const { siteid } = this.state
    //console.log('imageFile', imageFile, id);
    // const { authUser } = this.props;
    getFileURL(siteid, imageFile).then(res => {
      const url = res;

      let { imageList } = this.state;
      const imageData = { name: imageFile, url: url, id: id, live: false };
      imageList.push(Object.assign(imageData));
      this.setState({ imageList: imageList });
    })
  }

  openImageDialog = image => {
    this.setState({ openImage: true, currentImage: image });
  }

  handleCustomFields(customFieldData) {
    //console.log('handleCustomFields', customFieldData);
    //debugger;

    let { customFieldList } = this.state;
    //const imageData = { ...customFieldData }
    customFieldList.push(Object.assign(customFieldData));
    this.setState({ customFieldList: customFieldList });

  }

  handleCustomFieldsList(customFieldData) {
    this.setState({ customFieldList: customFieldData });
  }

  render() {
    const { classes, authUser } = this.props
    const { products, categories, subcategories, error, siteid } = this.state
    //console.log('products', products)
    return (
      <div>
        <CardBody>
          <Button onClick={this.handleDialogOpen('paper')}>Add New Product</Button>
          <AdminProductTable products={products} viewProductCTA={this.handDialogOpen2} />
          <CategoryAdmin siteid={siteid} />
          <Dialog
            open={this.state.openDialog}
            onClose={this.handleDialogClose}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <form className={classes.form} onSubmit={this.handleToUpdate}>
              <DialogTitle id="scroll-dialog-title">Product Details</DialogTitle>
              <DialogContent dividers={true} >

                <GridContainer>
                  <GridItem xs={12} sm={6} md={6} lg={6}>
                    <CustomInput
                      labelText="Product ID"
                      id="product_id"
                      name="product_id"
                      autoComplete="product_id"
                      autoFocus
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                        disabled: true,
                        value: this.state.product_id,
                        onChange: event => this.setState(byPropKey('product_id', event.target.value)),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Work className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} lg={6}>
                    <Button
                      color="primary"
                      onClick={() => {
                        this.setState({ openUpload: true })
                      }}
                    >
                      Add Product Image
                    </Button>
                    <ReorderList product={this.state} imageFile={this.handleImages} />
                    <EcommerceUploadDialog
                      open={this.state.openUpload}
                      onClose={this.handleUpload}
                      imageFile={this.handleImages}
                      siteid={this.state.siteid}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} lg={6}>
                    <CustomInput
                      labelText="Product Name..."
                      id="product_name"
                      name="product_name"
                      autoComplete="product_name"
                      autoFocus
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                        value: this.state.product_name,
                        onChange: event => this.setState(byPropKey('product_name', event.target.value)),
                        endAdornment: (
                          <InputAdornment position="end">
                            <People className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} lg={6}>
                    <CustomInput
                      labelText="Product Description..."
                      id="product_desc"
                      name="product_desc"
                      autoComplete="product_desc"
                      autoFocus
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                        multiline: true,
                        rows: 7,
                        value: this.state.product_desc,
                        onChange: event => this.setState(byPropKey('product_desc', event.target.value)),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Domain className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} lg={6}>
                    <CustomInput
                      labelText="Blurb..."
                      id="blurb"
                      name="blurb"
                      autoComplete="blurb"
                      autoFocus
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                        value: this.state.blurb,
                        onChange: event => this.setState(byPropKey('blurb', event.target.value)),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Domain className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} lg={6}>
                    <CustomInput
                      labelText="Price..."
                      id="price"
                      name="price"
                      autoComplete="price"
                      autoFocus
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                        value: this.state.price,
                        onChange: event => this.setState(byPropKey('price', event.target.value)),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Domain className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} lg={6}>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="category">Category...</InputLabel>
                      <Select
                        value={this.state.category}
                        onChange={event => this.handleCatChange(event, 'category')}
                        input={<Input name="category" id="category" />}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {categories.map((item, key) => {
                          return (
                            <MenuItem key={key} value={key}>
                              {key} {item.id}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6} lg={6}>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="subcategory">Sub Category...</InputLabel>
                      <Select
                        value={this.state.subcategory}
                        onChange={event => this.handleSubCatChange(event, 'subcategory')}
                        input={<Input name="subcategory" id="subcategory" />}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {subcategories.map((item, key) => {
                          return (
                            <MenuItem key={key} value={key}>
                              {key} {item.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <CustomInput
                      labelText="Tags..."
                      id="tags"
                      name="tags"
                      autoComplete="tags"
                      autoFocus
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                        value: this.state.tags,
                        onChange: event => this.setState(byPropKey('tags', event.target.value)),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Domain className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Button
                      color="primary"
                      onClick={() => {
                        this.setState({ openCustomField: true })
                      }}
                    >
                      Add Custom Field
                    </Button>
                    <CustomFieldList
                      product={this.state}
                      customFieldData={this.handleCustomFieldsList}
                    />
                    <EcommerceCustomFieldDialog
                      open={this.state.openCustomField}
                      onClose={this.handleCustomFieldUpdate}
                      customFieldData={this.handleCustomFields}
                    />
                  </GridItem>
                </GridContainer>
                {error && <p>{error.message}</p>}
              </DialogContent>
              <DialogActions>
                <Button color="primary" type="submit">
                  Save
                </Button>
                <Button onClick={this.handleDialogClose} color="primary">
                  Discard
                </Button>
              </DialogActions>
            </form>
          </Dialog>
          <Dialog
            open={this.state.openImage}
            onClose={this.handleImageClose}
            scroll={this.state.scroll}
            aria-labelledby="scroll-dialog-title"
          >
            <img className={classes.imageview} src={this.state.currentImage.url} />
          </Dialog>
        </CardBody>
        <CardFooter className={classes.cardFooter} />

        <br />
        <br />

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Updated</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    )
  }
}

const INITIAL_STATE = {
  id: undefined,
  product_id: '',
  product_name: '',
  product_desc: '',
  blurb: '',
  price: '',
  imageList: [],
  imgChecked: [],
  product: null,
  product_image1: '', // <!--- file ID
  product_image2: '', // <!--- file ID
  product_image3: '', // <!--- file ID
  category: '', //<!------- list that can be updated in ADMIN for each user
  subcategory: '', //<!------- list that can be updated in ADMIN for each user
  tags: '',
  customFieldList: [],
  error: null,
}

EcommerceAdmin.propTypes = {
  history: PropTypes.object,
  authUser: PropTypes.object,
  classes: PropTypes.object,
  rest: PropTypes.object,
  newImageList: PropTypes.object,
  items: PropTypes.array,
  currentUser: PropTypes.object,
  products: PropTypes.object,
  categories: PropTypes.object,
}

const mapStateToProps = state => ({
  // users: state.userState.users,
  // articles: state.articleState.articles,
  authUser: state.sessionState.authUser,
  newImageList: state.imageListState.imageList,
  products: state.productsState.products,
  categories: state.categoriesState.categories,
  imageToggle: state.imageToggleState.imageToggle,
  editArticle: state.editArticleState.editArticle,
  /*articles: state.firestore.ordered.articles
    ? state.firestore.ordered.articles
    : [],
  boards: state.firestore.ordered.boards ? state.firestore.ordered.boards : []*/
})

const mapDispatchToProps = dispatch => ({
  //onSetUsers: users => dispatch({ type: "USERS_SET", users }),
  //onSetArticles: articles => dispatch({ type: "ARTICLES_SET", articles }),
  onEditArticle: editArticle => dispatch({ type: 'EDIT_ARTICLE', editArticle }),
  onImageList: imageList => dispatch({ type: 'IMAGE_LIST_SET', imageList }),
  onImageToggle: imageToggle => dispatch({ type: 'IMAGE_TOGGLE', imageToggle }),
  onUpdateProducts: products => dispatch({ type: 'PRODUCTS_SET', products }),
  onUpdateCategories: categories => dispatch({ type: 'CATEGORIES_SET', categories }),
})

// const authCondition = authUser => !!authUser && authUser.role === "ADMIN";
export default compose(
  //withAuthorization(authCondition),
  withStyles(prospectStyle),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EcommerceAdmin)
