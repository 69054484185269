import { Button, Grid, Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Layout from "../components/Layout";
import { SurveyAdmin } from "../components";
// import { withUserContext } from "../contexts/User";

interface Props extends RouteComponentProps<void> { }

function SurveyAdminPage(props: Props) {
  
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddTodo = () => {
    setOpen(true);
  };

  return (
    <Layout theme={null}>
      <SurveyAdmin />
    </Layout>
  );
}


export default SurveyAdminPage;
