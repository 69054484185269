import * as React from "react";

import { Theme, makeStyles } from '@material-ui/core';

// material ui componentscomponents
import {
  AppBar,
  Badge,
  Divider,
  Drawer as DrawerMui,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  withWidth
} from "@material-ui/core";

// material ui icons
import MenuIcon from "@material-ui/icons/Menu";

// components
import {
  HeaderLinks,
  LayoutMenu,
  LayoutDrawer
} from "../components/";

const drawerWidth = 240;

// @ts-ignore "fuck CSS typings"
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%"
  },
  appBar: {
    position: "absolute",
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      position: "relative",
      height: "100vh"
    }
  },
  content: {
    flexGrow: 1,
    padding: "30px 15px"
  },
  flex: {
    flex: 1
  }
}));

interface Props {
  children: any,
  theme: any;
}

function Layout(props: Props) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  if (!props) {
    return null;
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { children } = props;
  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => handleDrawerToggle()}
            className={classes.navIconHide}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h4"
            color="inherit"
            noWrap
            className={classes.flex}
          >
            DASHBOARD
            </Typography>
            {/*
          <Hidden xsDown>
            <HeaderLinks />
          </Hidden>
            */}
            {/*
          <Hidden mdUp>
            <LayoutMenu
              error={null}
              history={null}
              location={null}
              match={null}
            />
          </Hidden>
          */}
        </Toolbar>
      </AppBar>
      <Hidden mdUp>
        {/* {theme.direction === "rtl" ? "right" : "left"} */}
        <DrawerMui
          variant="temporary"
          anchor={"left"}
          open={mobileOpen}
          onClose={() => handleDrawerToggle()}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true
          }}
        >
          <LayoutDrawer />
        </DrawerMui>
      </Hidden>
      <Hidden smDown implementation="css">
        <DrawerMui
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <LayoutDrawer />
        </DrawerMui>
      </Hidden>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}

export default Layout;
