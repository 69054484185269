import * as React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
// import PropTypes from "prop-types";

// @material-ui/core components
// import withStyles from "@material-ui/core/styles/withStyles";
import { Theme, makeStyles } from '@material-ui/core';


import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import Popper from "@material-ui/core/Popper";

// core components
import {
  Button,
  LayoutMenu
} from "../components/";

import customDropdownStyle from "../assets/jss/material-kit-react/components/customDropdownStyle";

// @ts-ignore "fuck CSS typings"
const useStyles = makeStyles((theme: Theme) => ({
  ...customDropdownStyle
}));

interface Props {
  children: any,
  theme: any,
  classes: any,
  buttonText: any,
  buttonIcon: any,
  dropdownList: any,
  buttonProps: any,
  dropup: any,
  dropdownHeader: any,
  caret: any,
  hoverColor: any,
  left: any,
  rtlActive: any,
  noLiPadding: any
}

function CustomDropdown({ ...props }) {

  const {
    buttonText,
    buttonIcon,
    dropdownList,
    buttonProps,
    dropup,
    dropdownHeader,
    caret,
    hoverColor,
    left,
    rtlActive,
    noLiPadding
  } = props;
  let anchorEl:any = null;

  const classes = useStyles() as any;
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = (param:any) => {
    setOpen(false);
    if (props && props.onClick) {
      props.onClick(param);
    }
  };

  const handleCloseAway = (event:any) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const caretClasses = classNames({
    [classes.caret]: true,
    [classes.caretActive]: open,
    [classes.caretRTL]: rtlActive
  });
  const dropdownItem = classNames({
    [classes.dropdownItem]: true,
    [classes[hoverColor + "Hover"]]: true,
    [classes.noLiPadding]: noLiPadding,
    [classes.dropdownItemRTL]: rtlActive
  });
  let icon = null;
  switch (typeof buttonIcon) {
    case "function":
      icon = <props.buttonIcon className={classes.buttonIcon} />;
      break;
    case "string":
      icon = (
        <Icon className={classes.buttonIcon}>{props.buttonIcon}</Icon>
      );
      break;
    default:
      icon = null;
      break;
  }
    return (
      <div>
        <div>
          <Button
            aria-label="Notifications"
            aria-owns={open ? "menu-list" : null}
            aria-haspopup="true"
            {...buttonProps}
            buttonRef={(node:any) => {
              anchorEl = node;
            }}
            onClick={handleClick}
          >
            {icon}
            {buttonText !== undefined ? buttonText : null}
            {caret ? <b className={caretClasses} /> : null}
          </Button>
        </div>
        <Popper
          open={open}
          anchorEl={anchorEl}
          transition
          disablePortal
          placement={
            dropup
              ? left
                ? "top-start"
                : "top"
              : left
              ? "bottom-start"
              : "bottom"
          }
          className={classNames({
            [classes.popperClose]: !open,
            [classes.popperResponsive]: true
          })}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              in={open}
              style={
                dropup
                  ? { transformOrigin: "0 100% 0" }
                  : { transformOrigin: "0 0 0" }
              }
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseAway}>
                  <MenuList role="menu" className={classes.menuList}>
                    {dropdownHeader !== undefined ? (
                      <MenuItem
                        onClick={() => handleClose(dropdownHeader)}
                        className={classes.dropdownHeader}
                      >
                        {dropdownHeader}
                      </MenuItem>
                    ) : null}
                    {dropdownList.map((prop:any, key:any) => {
                      if (prop.divider) {
                        return (
                          <Divider
                            key={key}
                            onClick={() => handleClose("divider")}
                            className={classes.dropdownDividerItem}
                          />
                        );
                      }
                      return (
                        <MenuItem
                          key={key}
                          onClick={() => handleClose(prop)}
                          className={dropdownItem}
                        >
                          {prop}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
}
/*
CustomDropdown.defaultProps = {
  caret: true,
  hoverColor: "primary"
};
*/
/*
CustomDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  hoverColor: PropTypes.oneOf([
    "black",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ]),
  buttonText: PropTypes.node,
  buttonIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  dropdownList: PropTypes.array,
  buttonProps: PropTypes.object,
  dropup: PropTypes.bool,
  dropdownHeader: PropTypes.node,
  rtlActive: PropTypes.bool,
  caret: PropTypes.bool,
  left: PropTypes.bool,
  noLiPadding: PropTypes.bool,
  // function that retuns the selected item
  onClick: PropTypes.func
};
*/
export default CustomDropdown;
