import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { RouteComponentProps, withRouter } from "react-router-dom";
// import * as firebase from "firebase/app";
import Firebase from "../services/Firebase";

// components
import {
  GridContainer,
  GridItem,
  Card,
  CardHeader,
  CardBody,
  CustomInput,
  CardFooter,
  Button,
  Header,
  HeaderLinks
} from "../components";

import { RootState } from "../reducers";

import loginPageStyle from "../assets/jss/material-kit-react/views/loginPage";
import image from "../assets/img/wakanda-bg.jpg";
import logo from "../assets/img/calogo.png";

// @ts-ignore "fuck CSS typings"
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    textAlign: "center",
    padding: 20,
    [theme.breakpoints.down("md")]: {
      paddingTop: 50,
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  centerContainer: {
    flex: 1,
    height: "90%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  button: {
    marginTop: 20,
  },
  ...loginPageStyle
}));

interface Props extends RouteComponentProps<void> {
  rest: any
}

function HomePageImpl(props: Props) {
  const classes = useStyles({});
  const { rest } = props;
  //cardAnimaton: "cardHidden"
  const [cardAnimaton, setCardAnimaton] = React.useState("cardHidden");
  const [boxColor, setBoxColor] = React.useState("red");
  const todoList = useSelector((state: RootState) => state.todoList);
  //todoList.length

  React.useEffect(():any => {
    // code to run on component mount
    let isSubscribed = true
    if (isSubscribed) {
      
      setTimeout(
        () => {
          isSubscribed && setCardAnimaton(""); //this.setState({ cardAnimaton: "" });
        },
        700
      );

    }
    return () => (isSubscribed = false)
  }, [])


  const onButtonClick = () => {
    Firebase.auth()
      .signOut()
      .then(res => {
        console.log("logged out", res);
      });
  }
  // setBoxColor(boxColor === "red" ? "blue" : "red");



  return (
    <div className={classes.root}>

      <Header
        absolute
        color="transparent"
        brand="Conway Alphonso | Home"
        rightLinks={<HeaderLinks />}
        {...rest}
      />

      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4}>
            <Card className={classes[cardAnimaton]}>

                <CardHeader color="primary" className={classes.cardHeader}>
                  <h4 className={classes.defaultFont}>
                    <img src={logo} />
                  </h4>
                  <div className={classes.socialLine}>
                    <Button
                      justIcon
                      href="#ca"
                      target="_blank"
                      color="transparent"
                    >
                      <i className={"fas fa-code"} />
                    </Button>
                    <Button
                      justIcon
                      href="#ca"
                      target="_blank"
                      color="transparent"
                    >
                      <i className={"fas fa-mobile-alt"} />
                    </Button>
                    <Button
                      justIcon
                      href="#ca"
                      target="_blank"
                      color="transparent"
                    >
                      <i className={"fas fa-laptop"} />
                    </Button>
                  </div>
                </CardHeader>
                <div
                  className={classnames([
                    classes.divider,
                    classes.defaultFont
                  ])}
                >
                  <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                    <li>Web Services</li>
                    <li>App Development</li>
                    <li>B2B Marketing</li>
                    <li>Event Management Tools</li>
                    <li>Multi Media Marketing</li>
                    <li>t: 07808 069 982</li>
                    <li>e: info@conwayalphonso.com</li>
                  </ul>
                </div>
              <br />
              <br />
            </Card>
          </GridItem>
        </GridContainer>
      </div>

    </div>
  );
}

const HomePage = withRouter(HomePageImpl as any);//Note: It is a workaround not an actual solution
export default HomePage;
