import React, { Component } from "react";
import PropTypes from "prop-types";
import { findDOMNode } from "react-dom";
//import { DragSource, DropTarget } from "react-dnd";
import ItemTypes from "./ItemTypes";
import Delete from "@material-ui/icons/Delete";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Checkbox from "@material-ui/core/Checkbox";

const style = {
  cursor: "move"
};

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index
    };
  }
};

const cardTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.moveListItem(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

/*
const withDropTarget = DropTarget(ItemTypes.LIST_ITEM, cardTarget, connect => ({
  connectDropTarget: connect.dropTarget()
}));

const withDropSource = DragSource(
  ItemTypes.LIST_ITEM,
  cardSource,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  })
);
*/

class CustomFieldListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      live: props.live
    };
  }
  static propTypes = {
    //connectDragSource: PropTypes.func.isRequired,
    //connectDropTarget: PropTypes.func.isRequired,
    // handleOnClick: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    //isDragging: PropTypes.bool.isRequired,
    id: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired
    ]),
    value: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    moveListItem: PropTypes.func.isRequired,
    live: PropTypes.bool,
    liveUpdate: PropTypes.func,
    deleteImage: PropTypes.func,
    url: PropTypes.string,
    classes: PropTypes.object,
    openCustomFieldDialog: PropTypes.func
  };

  handleImageToggle = (id, index) => () => {
    let { live } = this.state;
    live = live ? false : true;
    this.setState({ live: live });
    this.props.liveUpdate(index);
  };

  handleDeleteImage = index => () => {
    this.props.deleteImage(index);
  };

  render() {
    const {
      url,
      value,
      field,
      id,
      index,
      isDragging,
      //connectDragSource,
      //connectDropTarget,
      classes
      // props for onClick selection
      // handleOnClick
      //
    } = this.props;
    const { live } = this.state;
    const opacity = isDragging ? 0 : 1;

    return ( //connectDragSource(
      //connectDropTarget(
        <div style={{ ...style, opacity }}>
          <ListItem
            onClick={() => {
              this.props.openCustomFieldDialog(index,field,value);
            }}
            key={index}
            button
          >
            <ListItemText className={classes.wrapListText} primary={field} />
            <ListItemText className={classes.wrapListText} primary={value} />
            <ListItemSecondaryAction>
              <Checkbox
                onChange={this.handleImageToggle(id, index)}
                checked={live !== false}
              />
              <span className={classes.iconContainer}>
                <span className={classes.deleteIcon}>
                  <Delete
                    onClick={this.handleDeleteImage(index)}
                    className={classes.inputIconsColor}
                  />
                </span>
              </span>
            </ListItemSecondaryAction>
          </ListItem>
        </div>
      //)
    );
  }
}

//export default withDropTarget(withDropSource(CustomFieldListItem));
export default CustomFieldListItem;
