import React from 'react'
import { Link } from 'react-router-dom'
//import { Firebase } from '../utils/firebase';
import Helmet from 'react-helmet'

//import Button from '@material-ui/core/Button';
//import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
//import Link from '@material-ui/core/Link'
import Loading from './Loading'

// components
import { Button } from '../'

import Firebase, { doUpdateBookingData, firestore, siteid } from '../../services/Firebase'

class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dialogOpen: false,
      loading: true,
      polls: [], //items like { id: 34324, title: 'sdf'}
    }

    this.poll2Delete = ''
    this.poll2DeleteTitle = ''

    this.handleClose = this.handleClose.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  componentWillMount() {
    //const uid = getLocalUserId();

    Firebase.auth().onAuthStateChanged(user => {
      if (user) {
        //this can get called after componentWillUnmount, make sure its there to avoid errors

        const uid = user.uid //"stB6glHPoaOHI1Stdosp007bzoF3" //user.uid This needs to be siteid in real site for now its godbody@gmail.com uid

        this.userPollsRef = Firebase.database().ref(`user-polls/${uid}`)

        //check if user has no polls to quit loading indicator
        this.userPollsRef.once('value').then(snapshot => {
          if (!snapshot.hasChildren()) {
            if (this.mounted) {
              this.setState({ loading: false })
            }
          }
        })

        this.userPollsRef
          .on('child_added', newPollIdSnapshot => {
            const pollId = newPollIdSnapshot.key

            Firebase.database()
              .ref(`polls/${pollId}/title`)
              .once('value')
              .then(snapshot => {
                const title = snapshot.val()

                const polls = this.state.polls
                polls.push({ title: title, id: pollId })

                if (this.mounted) {
                  this.setState({
                    polls: polls,
                    loading: false,
                  })
                }
              })
          })
          .bind(this)

        this.userPollsRef
          .on('child_removed', removedPollIdSnapshot => {
            const pollId = removedPollIdSnapshot.key
            const polls = this.state.polls.filter(poll => poll.id !== pollId)

            if (this.mounted) {
              this.setState({
                polls: polls,
              })
            }
          })
          .bind(this)
      }
    })

    this.mounted = true //the callbacks above can be called after componentWillUnmount(), to avoid errors, check
  }

  componentWillUnmount() {
    this.userPollsRef.off()
    this.mounted = false
  }

  handleOpen(pollId) {
    this.setState({ dialogOpen: true })
    this.poll2Delete = pollId
    this.poll2DeleteTitle = this.state.polls.find(poll => poll.id === this.poll2Delete).title
  }

  handleClose() {
    this.setState({ dialogOpen: false })
  }

  handleDelete() {
    // updating to null deletes
    const updates = {}
    updates[`/polls/${this.poll2Delete}`] = null
    updates[`/user-polls/${Firebase.auth().currentUser.uid}/${this.poll2Delete}`] = null

    Firebase.database()
      .ref()
      .update(updates)

    this.setState({ dialogOpen: false })
  }

  render() {
    const actions = [
      <Button justIcon color="primary" onClick={this.handleClose}>
        Cancel
      </Button>,
      <Button justIcon color="primary" onClick={this.handleDelete}>
        Delete
      </Button>,
    ]

    let pollsUIs = this.state.polls.map(poll => {
      return (
        <div key={poll.id}>
          <Button onClick={() => this.handleOpen(poll.id)}>
            <i className={'fa fa-trash'} />
            Delete
          </Button>
          <Link color="inherit" to={`/polls/poll/${poll.id}`}>
            <Button style={{ textAlign: 'left', width: '50%' }}>{poll.title}</Button>
          </Link>
          <Divider />
        </div>
      )
    })

    return (
      <div className="row">
        <div className="col-sm-12 text-xs-center">
          <Helmet title="Dashboard" />

          <Paper>
            <br />
            <h2>Your Polls</h2>
            <br />

            <Dialog
              onClose={this.handleClose}
              aria-labelledby="Upload"
              aria-describedby="Upload"
              open={this.state.dialogOpen}
            >
              <DialogTitle>Upload File</DialogTitle>
              <DialogContent>
              Delete "{this.poll2DeleteTitle}"?
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary" >
                  Cancel
                </Button>
                <Button color="primary" onClick={this.handleDelete}>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>

            <Link color="inherit" to={`/polls/new`}>
              <Button color="primary" autoFocus>
                New Poll
              </Button>
            </Link>
            <br />
            <br />

            {pollsUIs}

            <Loading loading={this.state.loading} />

            <br />
            <br />
          </Paper>
        </div>
      </div>
    )
  }
}

export default Dashboard
