import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import classNames from "classnames";
// @material-ui/core components
import { Theme, makeStyles } from '@material-ui/core';

// @material-ui/icons

// core components
import cardStyle from "../assets/jss/material-kit-react/components/cardStyle";

interface Props extends RouteComponentProps<void> {
  className: any,
  children: any,
  plain: boolean,
  carousel: boolean
}

function Card(props: Props) {
  const classes = useStyles();
  const { className, children, plain, carousel, ...rest } = props;
  const cardClasses = classNames({
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.cardCarousel]: carousel,
    [className]: className !== undefined
  });
  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
}

// @ts-ignore "fuck CSS typings"
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    
  },
  ...cardStyle
}));

export default Card as React.ComponentType<any>;
