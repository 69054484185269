/*eslint-disable*/
import * as React from "react";
// react components for routing our app without refresh
import { RouteComponentProps, Link } from "react-router-dom";

// @material-ui/core components
import { Theme, makeStyles } from '@material-ui/core';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import {
  Apps,
  CloudDownload,
  Home,
  Fingerprint,
  Person,
  ShoppingBasket,
  Send
} from "@material-ui/icons";

// components
// import CustomDropdown from "components/CustomDropdown/CustomDropdown.tsx";
import {
  Button,
  LayoutMenu,
  CustomDropdown
} from "../components/";

// styles
import headerLinksStyle from "../assets/jss/material-kit-react/components/headerLinksStyle";
import { defaultFont } from "../assets/jss/material-kit-react";

// @ts-ignore "fuck CSS typings"
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 20,
    [theme.breakpoints.down("md")]: {
      paddingTop: 50,
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  list: {
    ...defaultFont,
    fontSize: "14px",
    margin: 0,
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: "inherit"
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:after": {
        width: "calc(100% - 30px)",
        content: '""',
        display: "block",
        height: "1px",
        marginLeft: "15px",
        backgroundColor: "#e5e5e5"
      }
    }
  },
  listItemText: {
    padding: "0 !important"
  },
  navLink: {
    color: "inherit",
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: "inherit",
      background: "rgba(200, 200, 200, 0.2)"
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start"
      }
    }
  },
  ...headerLinksStyle
}));

export interface Props extends RouteComponentProps<void> {
  user: any,
  error: any,
  history: any
}

function HeaderLinks({ ...props }) {
  const classes = useStyles();
  const [history, setHistory] = React.useState(props.history);
  return (
    <List className={classes.list}>
      {/*
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Components"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link to="/" className={classes.dropdownLink}>
              All components
            </Link>,
            <a
              href="https://creativetimofficial.github.io/material-kit-react/#/documentation"
              target="_blank"
              className={classes.dropdownLink}
            >
              Documentation
            </a>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="https://www.creative-tim.com/product/material-kit-react"
          color="transparent"
          target="_blank"
          className={classes.navLink}
        >
          <CloudDownload className={classes.icons} /> Download
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-twitter"
          title="Follow us on twitter"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <span>
            <Button
              href="https://twitter.com/CreativeTim"
              target="_blank"
              color="transparent"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fab fa-twitter"} />
            </Button>
          </span>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <span>
          <Button
            color="transparent"
            href="https://www.facebook.com/CreativeTim"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
          </span>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Follow us on instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <span>
          <Button
            color="transparent"
            href="https://www.instagram.com/CreativeTimOfficial"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
          </span>
        </Tooltip>
      </ListItem>
        */}
      <ListItem className={classes.listItem}>
        <Link to={"/home"} className={classes.navLink}>
          <Button
            color="transparent"
            target="_blank"
            className={classes.navLink}
          >
            <Home className={classes.icons} /> Home
          </Button>
        </Link>
      </ListItem>
      {/*
      <ListItem className={classes.listItem}>
        <Link to={"/about"} className={classes.navLink}>
          <Button
            color="transparent"
            target="_blank"
            className={classes.navLink}
          >
            <Fingerprint className={classes.icons} /> About
          </Button>
        </Link>
      </ListItem>
      */}
      {/*
      <ListItem className={classes.listItem}>
        <Link to={"/bio"} className={classes.navLink}>
          <Button
            color="transparent"
            target="_blank"
          >
            <Person className={classes.icons} /> Biography
          </Button>
        </Link>
      </ListItem>
      */}
      {/*
      <ListItem className={classes.listItem}>
        <Link to={"/order"} className={classes.navLink}>
          <Button
            color="transparent"
            target="_blank"
            className={classes.navLink}
          >
            <ShoppingBasket className={classes.icons} /> Order
          </Button>
        </Link>
      </ListItem>
      */}
      {/*
      <ListItem className={classes.listItem}>
        <Link to={"#contactus"} className={classes.navLink}>
          <Button
            color="transparent"
            className={classes.navLink}
          >
            <Send className={classes.icons} /> Contact
          </Button>
        </Link>
      </ListItem>
      */}
      <ListItem className={classes.listItem}>
        <LayoutMenu
          error={null}
          history={history}
          location={null}
          match={null}
        />
      </ListItem>
    </List>
  );
}

export default HeaderLinks;
