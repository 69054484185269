import { History } from "history";
import { combineReducers } from "redux";
import { Todo } from "../model/model";

import * as todoReducer from "./todo";
import sessionReducer from "./session";
import userReducer from "./user";
import articleReducer from "./article";
import userAuthReducer from "./userauth"; //delete this
import editArticleReducer from "./editarticle";
import imageListReducer from "./imageList";
import customFieldListReducer from "./customfieldList";
import imageToggleReducer from "./imageToggle";
import productsReducer from "./products";
import categoriesReducer from "./categories";
import ordersReducer from "./orders";


export interface RootState {
	todoList: Todo[];
}

export default (history: History) =>
	combineReducers({
		sessionState: sessionReducer,
		userState: userReducer,
		// firebase: firebaseReducer,
		// firestore: firestoreReducer,
		articleState: articleReducer,
		userAuthState: userAuthReducer, // delete this
		editArticleState: editArticleReducer,
		// allArticlesState: allarticlesReducer,
		imageListState: imageListReducer,
		customFieldListState: customFieldListReducer,
		imageToggleState: imageToggleReducer,
		productsState: productsReducer,
		categoriesState: categoriesReducer,
		ordersState: ordersReducer,
		...todoReducer,
	});
