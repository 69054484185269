import React from "react";
import PropTypes from "prop-types";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";

import MoreVertIcon from "@material-ui/icons/MoreVert";

import ConfirmationDialog from "../ConfirmationDialog";

import { deleteProduct } from "../../services/Firebase";

class AdminProductTableMenu extends React.Component {
  state = {
    anchorEl: null,
    openConfirmation: false,
    operation: null
  };

  handleOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleDelete = () => {
    this.handleClose();
    this.setState({ openConfirmation: true, operation: "delete" });
  };

  handleEdit = () => {
    this.handleClose();
    this.props.viewProduct(this.props.product);
  };

  handleConfirm = confirmed => {
    this.setState({ openConfirmation: false });
    if (confirmed) {
      if (this.state.operation === "delete") {
        deleteProduct(this.props.product.client_id, this.props.product.id);
      }
    }
  };

  render() {
    const { anchorEl } = this.state;
    return (
      <div>
        <IconButton
          aria-owns={anchorEl ? "simple-menu" : null}
          aria-haspopup="true"
          onClick={this.handleOpen}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleEdit}>Edit</MenuItem>
          <MenuItem onClick={this.handleDelete}>Delete</MenuItem>
        </Menu>
        <ConfirmationDialog
          open={this.state.openConfirmation}
          content={`Are you sure you want to remove ${
            this.props.product.product_name
          } ?`}
          onClose={this.handleConfirm}
        />
      </div>
    );
  }
}
AdminProductTableMenu.propTypes = {
  classes: PropTypes.object,
  products: PropTypes.array,
  product: PropTypes.object,
  viewProduct: PropTypes.func
};
export default AdminProductTableMenu;
