import React, { Component } from 'react';
// import { withRouter } from 'react-router-dom';
// import { connect } from "react-redux";
import compose from "recompose/compose";
import { Link } from 'react-router-dom';
// import { withRouter } from 'react-router-dom';


import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

// import { SignUpLink } from './SignUp';
// import { PasswordForgetLink } from './PasswordForget';
//import { auth, db } from '../firebase';
import Firebase, { doCreateUserWithEmailAndPassword, doCreateUser } from "../services/Firebase";
import * as routes from '../constants/routes';

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
});

const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value,
});

const INITIAL_STATE = {
    username: '',
    lastname: '',
    email: '',
    passwordOne: '',
    passwordTwo: '',
    error: null,
};

class SignUpPage extends Component {

    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = (event) => {
        const {
            username,
            lastname,
            email,
            passwordOne,
        } = this.state;

        const {
            history,
        } = this.props;

        doCreateUserWithEmailAndPassword(email, passwordOne)
            .then(authUser => {
                // Create a user in your own accessible Firebase Database too
                doCreateUser(authUser.user.uid, username, lastname, email)
                    .then(() => {
                        this.setState({ ...INITIAL_STATE });
                        history.push(routes.HOME);
                    })
                    .catch(error => {
                        this.setState(byPropKey('error', error));
                    });
            })
            .catch(error => {
                this.setState(byPropKey('error', error));
            });

        event.preventDefault();
    }

    render() {
        const { classes } = this.props;
        //console.log('this.props', this.props);

        const {
            username,
            lastname,
            email,
            passwordOne,
            passwordTwo,
            error,
        } = this.state;

        const isInvalid =
            passwordOne !== passwordTwo ||
            passwordOne === '' ||
            email === '' ||
            username === '' ||
            lastname === '';

        return (
            <main className={classes.main}>
                <CssBaseline />
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign Up
                    </Typography>
                    <form className={classes.form} onSubmit={this.onSubmit}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="username">Username</InputLabel>
                            <Input
                                value={username}
                                onChange={event => this.setState(byPropKey('username', event.target.value))} 
                                id="username" 
                                name="username" 
                                autoComplete="username" 
                                autoFocus />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="lastname">Last Name</InputLabel>
                            <Input
                                value={lastname}
                                onChange={event => this.setState(byPropKey('lastname', event.target.value))} 
                                id="lastname" 
                                name="lastname" 
                                autoComplete="lastname" 
                                 />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="email">Email Address</InputLabel>
                            <Input
                                value={email}
                                onChange={event => this.setState(byPropKey('email', event.target.value))} 
                                id="email" 
                                name="email" 
                                autoComplete="email" 
                                 />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="passwordOne">Password</InputLabel>
                            <Input
                                value={passwordOne}
                                onChange={event => this.setState(byPropKey('passwordOne', event.target.value))}
                                name="passwordOne" 
                                type="password" 
                                id="passwordOne" 
                                autoComplete="current-passwordOne" />
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="passwordTwo">Password Confirm</InputLabel>
                            <Input
                                value={passwordTwo}
                                onChange={event => this.setState(byPropKey('passwordTwo', event.target.value))}
                                name="passwordTwo" 
                                type="password" 
                                id="passwordTwo" 
                                autoComplete="current-passwordTwo" />
                        </FormControl>
                        <Button
                            disabled={isInvalid}
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Sign Up
                        </Button>
                        {error && <p>{error.message}</p>}
                    </form>
                    
                </Paper>
            </main>
        );
    }
}

SignUpPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

const SignUpLink = () =>
    <p>
        Don't have an account?
    {' '}
        <Link to={routes.SIGN_UP}>Sign Up</Link>
    </p>

export default compose(
    withStyles(styles)
)(SignUpPage);

export {
    
    SignUpLink,
};
