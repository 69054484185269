import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { history } from "../configureStore";
import classnames from "classnames";

/*
import SyncImage from "../assets/images/Sync.svg";
*/
// Material UI
import { Theme, makeStyles } from '@material-ui/core';
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
import Lock from "@material-ui/icons/Lock";

// components
import {
  GridContainer,
  GridItem,
  Card,
  CardHeader,
  CardBody,
  CustomInput,
  CardFooter,
  Button,
  Header,
  HeaderLinks
} from "../components";

//import FormControlLabel from "@material-ui/core/FormControlLabel";
//import Checkbox from "@material-ui/core/Checkbox";
//import Check from "@material-ui/icons/Check";

// Styles
import loginPageStyle from "../assets/jss/material-kit-react/views/loginPage";
import image from "../assets/img/bg7.jpg";
// import "assets/scss/material-kit-react.scss?v=1.4.0";

// services
import Firebase, { doCreateUserWithEmailAndPassword, doCreateUser, theuser, doSendEmailVerification } from "../services/Firebase";
import * as routes from '../constants/routes';

// @ts-ignore "fuck CSS typings"
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 20,
    position: "absolute",
    height: "100%",
    width: "100%",
    margin: 0,
    [theme.breakpoints.down("md")]: {
      paddingTop: 50,
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  image: {
    maxWidth: 300,
    display: "block",
    margin: "32px auto"
  },
  side: {
    height: "100%"
  },
  leftSide: {},
  rightSide: {
    backgroundColor: theme.palette.primary.dark
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "white",
    paddingRight: theme.spacing(2),
    minWidth: 200
  },
  buttonIcon: {
    fontSize: 32,
    marginLeft: theme.spacing(1),
    paddingTop: 5
  },
  text: {
    color: theme.palette.primary.contrastText
  },
  ...loginPageStyle
}));

/*
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});
*/

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null
};

export interface Props extends RouteComponentProps<void> {
  error: any,
  rest: any
}

function SignIn(props: Props) {

  const classes = useStyles();
  const [cardAnimaton, setCardAnimaton] = React.useState("cardHidden");
  const [checked, setChecked] = React.useState([]);
  const [selectedEnabled, setSelectedEnabled] = React.useState("b");
  const [checkedA, setCheckedA] = React.useState(true);
  const [checkedB, setCheckedB] = React.useState(false);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [passwordOne, setPasswordOne] = React.useState("");
  const [passwordTwo, setPasswordTwo] = React.useState("");
  const [email, setEmail] = React.useState("");
  //const [authUser, setAuthUser] = React.useState({});
  const [error, setError] = React.useState();
  const [username, setUsername] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [rest, setRest] = React.useState(props.rest);

  let _isMounted = false;
  let authUser = {}

  //class SignIn extends React.Component {
  /*
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      cardAnimaton: "cardHidden",
      checked: [],
      selectedEnabled: "b",
      checkedA: true,
      checkedB: false,
      labelWidth: 0
    };
    this._isMounted = false;
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this._isMounted = true;
    this._isMounted &&
      setTimeout(
        function() {
          this._isMounted && this.setState({ cardAnimaton: "" });
        }.bind(this),
        700
      );
  }

  componentWillUnmount() {
    const { username, lastname, email, authUser, checked } = this.state;
    const roles = {};
    if (authUser) {
      // todo: Will need better management of this checked value
      checked.forEach(c => {
        c === 1 ? (roles["ADMIN"] = "ADMIN") : null;
      });

      let unregister = doCreateUser(
        authUser.user.uid,
        username,
        lastname,
        email,
        roles
      )
        .then(() => {
          // this.setState({ ...INITIAL_STATE });
          // history.push(routes.LOGIN);
          Firebase.auth().signOut();
        })
        .catch(error => {
          this.setState(byPropKey("error", error));
        });
      this._isMounted = false;
      unregister;
    }
  }
  */

  React.useEffect(() => {
    // code to run on component mount
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    let isSubscribed = true
    _isMounted = true;
    _isMounted &&
      setTimeout(
        () => {
          if (isSubscribed) {
          _isMounted && setCardAnimaton("");
          }
        },
        700
      );
      return () => isSubscribed = false as any;
  }, [cardAnimaton])


  const handleToggle = (value: never) => {
    // const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  }

  const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

  const ERROR_MSG_ACCOUNT_EXISTS = `
    An account with this E-Mail address already exists.
    Try to login with this account instead. If you think the
    account is already used from one of the social logins, try
    to sign in with one of them. Afterward, associate your accounts
    on your personal account page.
  `;

  const onSubmit = (event: any) => {
    
    const roles = {};

    //if (isAdmin) {
    //  roles[ROLES.ADMIN] = ROLES.ADMIN;
    //}

    doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(newAuthUser => {
        // Create a user in your Firebase realtime database
        authUser = newAuthUser;
        return doCreateUser(newAuthUser.user.uid, username, lastname, email, roles)
      })
      .then(() => {
        return doSendEmailVerification(authUser);
      })
      .then(() => {
        history.push(routes.ACCOUNT);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }
        setError(error);
      });
    event.preventDefault();
  };
  const isInvalid =
    passwordOne !== passwordTwo ||
    passwordOne === "" ||
    email === "" ||
    username === "" ||
    lastname === "";
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Conway Alphonso | Login"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form} onSubmit={onSubmit}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4 className={classes.defaultFont}>Sign Up</h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText="First Name..."
                      id="username"
                      name="username"
                      autoComplete="username"
                      autoFocus
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        onChange: (e: any) => setUsername(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <People className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                    />
                    <CustomInput
                      labelText="Last Name..."
                      id="lastname"
                      name="lastname"
                      autoComplete="lastname"
                      autoFocus
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        onChange: (e: any) => setLastname(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <People className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                    />
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      name="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "email",
                        onChange: (e: any) => setEmail(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                    />
                    <CustomInput
                      labelText="Password"
                      id="passwordOne"
                      name="passwordOne"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password",
                        onChange: (e: any) => setPasswordOne(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              <Lock className={classes.inputIconsColor} />
                              </Icon>
                          </InputAdornment>
                        )
                      }}
                      onChange={(e: any) => setPasswordOne(e.target.value)}
                    />
                    <CustomInput
                      labelText="Password Confirm"
                      id="passwordTwo"
                      name="passwordTwo"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password",
                        onChange: (e: any) => setPasswordTwo(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                            <Lock className={classes.inputIconsColor} />
                              </Icon>
                          </InputAdornment>
                        )
                      }}
                      onChange={(e: any) => setPasswordTwo(e.target.value)}
                    />
                    {error && <p>{error['message']}</p>}
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button
                      simple
                      color="primary"
                      size="lg"
                      disabled={isInvalid}
                      type="submit"
                    >
                      Sign Up
                      </Button>
                  </CardFooter>
                </form>
                <br />
                <br />
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
  // }
}

export default SignIn;
