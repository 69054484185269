import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import classNames from "classnames";

// @material-ui/core components
import { Theme, makeStyles } from '@material-ui/core';
import Button from "@material-ui/core/Button";

// core components

import buttonStyle from "../assets/jss/material-kit-react/components/buttonStyle";

interface Props extends RouteComponentProps<void> {
  simple:boolean,
  color:"primary"|
  "info"|
  "success"|
  "warning"|
  "danger"|
  "rose"|
  "white"|
  "facebook"|
  "twitter"|
  "google"|
  "github"|
  "transparent",
  size:"sm" | "lg",
  disabled:boolean,
  rount: boolean,
  children: any,
  fullWidth: boolean,
  block: boolean,
  link: boolean,
  justIcon: boolean,
  className:  any,
  round: boolean
}

function RegularButton(props: Props) {
  const classes = useStyles();
  const {
    color,
    round,
    children,
    fullWidth,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    ...rest
  } = props;
  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className
  });
  return (
    <Button {...rest} className={btnClasses}>
      {children}
    </Button>
  );
}

// @ts-ignore "fuck CSS typings"
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    
  },
  ...buttonStyle
}));

export default RegularButton as React.ComponentType<any>;
