const INITIAL_STATE = {
  customFieldList: {}
};

const applySetCustomFieldList = (state, action) => ({
  ...state,
  customFieldList: action.customFieldList
});

function customFieldListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "CUSTOM_FIELD_LIST_SET": {
      return applySetCustomFieldList(state, action);
    }
    default:
      return state;
  }
}

export default customFieldListReducer;
