const INITIAL_STATE = {
  ImageToggle: "xxx"
};

const applySetImageToggle = (state, action) => ({
  ...state,
  ImageToggle: action.ImageToggle
});

function ImageToggleReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "IMAGE_TOGGLE": {
      return applySetImageToggle(state, action);
    }
    default:
      return state;
  }
}

export default ImageToggleReducer;
