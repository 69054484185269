import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import classnames from "classnames";
import Firebase from "../services/Firebase";
// import { useActions } from "../actions";
// import * as fsActions from "../actions/fs";

// Material UI
import { Theme, makeStyles } from '@material-ui/core';
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
// import Icon from "@material-ui/core/Icon";
import LockOutlined from "@material-ui/icons/LockOutlined";
import Email from "@material-ui/icons/Email";

// Styles
import loginPageStyle from "../assets/jss/material-kit-react/views/loginPage";
import image from "../assets/img/bg7.jpg";

// components
import {
  GridContainer,
  GridItem,
  Card,
  CardHeader,
  CardBody,
  CustomInput,
  CardFooter,
  Button,
  Header,
  HeaderLinks
} from "../components";
import { SignUpLink } from './SignUp';
import { PasswordForgetLink } from './PasswordForget3';

// @ts-ignore "fuck CSS typings"
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 20,
    [theme.breakpoints.down("md")]: {
      paddingTop: 50,
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  ...loginPageStyle
}));

export interface Props extends RouteComponentProps<void> {
  user: any,
  error: any,
  rest: any
}

function LoginPage(props: Props) {
  
  const { ...rest } = props;
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState();
  // const [cardAnimaton, setCardAnimaton] = React.useState("");
  const cardAnimaton = "";
  const [history, setHistory] = React.useState(props.history);
  // const [user, setUser] = React.useState(props.user);
  const user = props.user;
  const _isMounted = false;

  const isInvalid = password === "" || email === "";

  /*
  const handleClose = () => {
    setOpen(false);
  };
  */
  /*
  const handleLogin = () => {
    setOpen(true);
  };
  */
  const onSignIn = () => {
    if (user) {
      history.push("/");
      return setHistory(history);
    };

    const provider = new Firebase.auth.GoogleAuthProvider();
    _isMounted &&
      Firebase.auth()
        .signInWithPopup(provider)
        .then();
  }

  const onSubmit = (event: any) => {

    Firebase.auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        console.log('logged in');

        return history.push("/");
      })
      .catch(error => {
        setError(error);
      });

    event.preventDefault();
  };
  // following might be used in services instead of current method. I'm still not sure about subscribing etc...
  /*
    (async () => {
      // const compared = await compareHashPassword(pw, originPw);
      this.unregister = await doAddUserEcommerceSubCatData(
        uid,
        category,
        newsubcat
      ).then(res => {
        console.log("DONE!!!");
        this.setState({ open: true, categorymsg: res }); // snackbar message
      });
    })();
  */

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Conway Alphonso | Login"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >

        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form} onSubmit={onSubmit}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4 className={classes.defaultFont}>Login</h4>
                    <div className={classes.socialLine}>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                      >
                        <i className={"fab fa-twitter"} />
                      </Button>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"

                      >
                        <i className={"fab fa-facebook"} />
                      </Button>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={onSignIn()}
                      >
                        <i className={"fab fa-google-plus-g"} />
                      </Button>
                    </div>
                  </CardHeader>
                  <p
                    className={classnames([
                      classes.divider,
                      classes.defaultFont
                    ])}
                  >
                    Or Be Classical
                    </p>
                  <CardBody>
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      name="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "email",
                        onChange: (e: any) => setEmail(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                    />
                    <CustomInput
                      labelText="Password"
                      id="password"
                      name="password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password",
                        onChange: (e: any) => setPassword(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockOutlined className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                      onChange={(e: any) => setPassword(e.target.value)}
                    />
                    {error && <p>{error['message']}</p>}
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button
                      simple
                      color="primary"
                      size="lg"
                      disabled={isInvalid}
                      type="submit"
                    >
                      Login
                      </Button>
                  </CardFooter>
                </form>
                <CardBody className={classes.defaultFont}>
                  
                  <PasswordForgetLink />
                  <SignUpLink />
                </CardBody>
                <br />
                <br />
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;