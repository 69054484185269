import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from "../Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import GridContainer from "../GridContainer";
import GridItem from "../GridItem";

// @material-ui/icons
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
// import AdminProductTableMenu from "./AdminProductTableMenu.jsx";

import LinearProgress from '@material-ui/core/LinearProgress'

import { withStyles } from '@material-ui/core/styles'

import headerLinksStyle from '../../assets/jss/material-kit-react/components/headerLinksStyle.jsx'

import Firebase, {
  firestore,
  siteid,
  getSurveyQuestionData
} from "../../services/Firebase";

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {},
  tableWrapper: {
    overflowX: 'auto',
  },
  tableRowName: {
    paddingRight: 0,
  },
  tableRowNameIcon: {
    paddingRight: 16,
    color: theme.palette.primary.dark,
  },
  tableRowNameText: {
    paddingTop: 5,
  },
  notFound: {
    paddingTop: 32,
  },
  notFoundImage: {
    width: 320,
    opacity: 0.5,
  },
  tableColumnWidth: {
    width: '25%',
    maxWidth: 200,
    overflow: 'hidden',
  },
  adminNavLink: {
    color: '#000',
    textDecoration: 'none',
    '&:hover': {
      color: '#000',
    },
  },
  ...headerLinksStyle,
})
const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
})

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0)
  }

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1)
  }

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1)
  }

  handleLastPageButtonClick = event => {
    this.props.onChangePage(event, Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1))
  }

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props

    return (
      <div className={classes.root}>
        <IconButton onClick={this.handleFirstPageButtonClick} disabled={page === 0} aria-label="First Page">
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={this.handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    )
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
}

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(TablePaginationActions)

class SurveyAdmin extends React.Component {
  state = {
    products: this.props.products,
    userforms: [],
    ready: false,
    search: '',
    page: 0,
    rowsPerPage: 5,
    openDialog: false,
    surveyDataList: {},
  }

  constructor(props) {
    super(props)
    // we use this to make the card to appear after the page has been rendered
    this.viewProduct = this.viewProduct.bind(this)
  }

  componentDidMount() {
    // const currentUser = Firebase.auth().currentUser;
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
    this.loadData();
  }

  loadData() {
    const db = firestore();
    this.unsubscribe3 = new Promise(resolve => {
      Promise.all([
        (this.unsubscribe = db
          .collection(`users/${siteid}/survey`) //userforms
          .onSnapshot(querySnapshot => {
            let userforms = [];
            querySnapshot.forEach(doc => {
              let userform = doc.data();
              userforms.push(
                Object.assign({ id: doc.id, client_id: siteid }, userform)
              );
            });
            this.setState({
              userforms: userforms,
              userformsSource: userforms,
              ready: true
            });
          }))
      ]).then(result => {
        resolve(result);
      });
    });
  }

  viewProduct(product) {
    this.props.viewProductCTA('paper', product)
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value })
  }

  handleDialogOpen = (scroll, data) => () => {

    delete data.id;
    delete data.client_id;
    delete data.siteid;
    delete data.uid;
    getSurveyQuestionData(siteid, data.survey).then(q => {
      this.setState({
        openDialog: true,
        scroll,
        surveyDataList: data,
        questions: q,
        viewEmail: data.email,
        viewUsername: data.username,
        viewLastname: data.lastname,
        viewSurvey: data.survey
      });
    });
  };

  handleDialogClose = () => {
    this.setState({ openDialog: false });
  };

  render() {
    const { classes } = this.props
    const { rowsPerPage, page, userforms, surveyDataList } = this.state
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, userforms.length - page * rowsPerPage)

    return this.state.ready ? (
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <Hidden xsDown smDown>
                <TableCell>Name</TableCell>
              </Hidden>
              <Hidden xsDown smDown>
                <TableCell>Survey Name</TableCell>
              </Hidden>
              <TableCell>Survey Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userforms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, key) => {
              return (
                <TableRow key={key}>
                  <TableCell className={classnames([classes.tableRowName, classes.tableColumnWidth])}>
                    <Grid container direction={'row'}>
                      <Grid item className={classes.tableRowNameText}>
                        {item.email}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <Hidden xsDown smDown>
                    <TableCell className={classnames([classes.tableRowName, classes.tableColumnWidth])}>
                      {item.username} {item.lastname}
                    </TableCell>
                  </Hidden>
                  <Hidden xsDown>
                    <TableCell className={classnames([classes.tableRowName, classes.tableColumnWidth])}>
                      {item.survey}
                    </TableCell>
                  </Hidden>
                  <TableCell>
                    <Button onClick={this.handleDialogOpen("paper", item)}>
                      Survey Details
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 48 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={3}
                count={userforms.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  native: false,
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActionsWrapped}
              />
            </TableRow>
          </TableFooter>
        </Table>

        <Dialog
          open={this.state.openDialog}
          onClose={this.handleDialogClose}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
        >
          <form className={classes.form} onSubmit={this.handleToUpdate}>
            <DialogTitle id="scroll-dialog-title">Survey Results</DialogTitle>
            <DialogContent>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                      <h1>{ this.state.viewSurvey }</h1>
                      <h2>{ this.state.viewEmail }</h2>
                      <h3>{ this.state.viewUsername } { this.state.viewLastname }</h3>
                      <Table className={classes.table}>
                        <TableBody>
                          {Object.keys(this.state.surveyDataList).map((key) => {
                            if(key === "email" || key === "username" || key === "lastname" || key === "survey"){
                              return null
                            } else {
                            return (
                              <TableRow key={key}>
                                <TableCell>
                                  {this.state.questions[key]}
                                </TableCell>
                                <TableCell>
                                {this.state.surveyDataList[key]}
                                </TableCell>
                              </TableRow>
                            )
                            }
                          })}
                        </TableBody>
                      </Table>
                </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleDialogClose} color="primary">
                Discard
              </Button>
            </DialogActions>
          </form>
        </Dialog>

      </div>
    ) : (
      <LinearProgress />
    )
  }
}
SurveyAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
  userforms: PropTypes.array,
  viewProductCTA: PropTypes.func,
}

export default withStyles(styles)(SurveyAdmin)
