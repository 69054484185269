import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
// import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import { Theme } from "@material-ui/core/styles";
import { WithStyles, createStyles } from '@material-ui/core/styles';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";

import { Theme, makeStyles } from '@material-ui/core';

// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import headerStyle from "../assets/jss/material-kit-react/components/headerStyle";

// @ts-ignore "fuck CSS typings"
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 20,
    [theme.breakpoints.down("md")]: {
      paddingTop: 50,
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  ...headerStyle
}));

interface Props  extends RouteComponentProps<void>{
  children: any,
  className?: any,
  history: any,
  location: any,
  match: any,
  xs:any,
  sm:any,
  md:any,
  changeColorOnScroll:any,
  classes:any, 
  color:any,
  rightLinks:any,
  leftLinks:any,
  brand:any,
  fixed:any,
  absolute:any
}

function Header(props: Props) {
//class Header extends React.Component {
  // constructor(props) {
  //  super(props);
    // this.state = {
    //  mobileOpen: false
    // };
    //const [mobileOpen, setMobileOpen] = React.useState(false);
    // const handleDrawerToggle:any; // = this.handleDrawerToggle.bind(this);
    // const headerColorChange:any; // = this.headerColorChange.bind(this);
  // }
  
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    // this.setState({ mobileOpen: !this.state.mobileOpen });
    setMobileOpen(!mobileOpen);
  }

  const classes = useStyles();
  const { color, changeColorOnScroll } = props;

  React.useEffect(() => {
    // code to run on component mount
    let isSubscribed = true
    if (changeColorOnScroll) {
      if (isSubscribed) {
      window.addEventListener("scroll", headerColorChange);
      }
    }
    return () => isSubscribed = false as any;
  }, [])

  /*
  componentDidMount() {
    if (this.props.changeColorOnScroll) {
      window.addEventListener("scroll", this.headerColorChange);
    }
  }
  */

  const headerColorChange = () => {
    // const { classes, color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  }

  const {
    rightLinks,
    leftLinks,
    brand,
    fixed,
    absolute
  } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });
  const brandComponent = <Button className={classes.title}>{brand+''}</Button>; // weird hack to stop button generating prop error

  // return (
  // render() {

    return (
      <AppBar className={appBarClasses}>
        <Toolbar className={classes.container}>
          {leftLinks !== undefined ? brandComponent : null}
          <div className={classes.flex}>
            {leftLinks !== undefined ? (
              <Hidden smDown implementation="css">
                {leftLinks}
              </Hidden>
            ) : (
              brandComponent
            )}
          </div>
          <Hidden smDown implementation="css">
            {rightLinks}
          </Hidden>
          <Hidden mdUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => handleDrawerToggle()}
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={mobileOpen}
            classes={{
              paper: classes.drawerPaper
            }}
            onClose={() => handleDrawerToggle()}
          >
            <div className={classes.appResponsive}>
              {leftLinks}
              {rightLinks}
            </div>
          </Drawer>
        </Hidden>
      </AppBar>
    );
  //}
}

/*
componentWillUnmount() {
  if (changeColorOnScroll) {
    window.removeEventListener("scroll", this.headerColorChange);
  }
}
*/
/*
Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // this.props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // this.props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};
*/

export default Header as React.ComponentType<any>;
