const INITIAL_STATE = {
  orders: {}
};

const applySetOrders = (state, action) => ({
  ...state,
  orders: action.orders
});

function ordersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "IMAGE_LIST_SET": {
      return applySetOrders(state, action);
    }
    default:
      return state;
  }
}

export default ordersReducer;
