import * as React from "react";
import firebase from "firebase/app";

interface UserContext {
  user?: firebase.User;
  initialising?: boolean;
}

export const userContext = React.createContext<UserContext>({
  user: undefined
});

export const withUserContext = (Component:any) => {
  return (props:any) => {
      return (
          <userContext.Consumer>
              {(user) => {
                //console.log("userContext.Consumer", user, props);
                  return <Component {...props} user={user}/>
              }}
          </userContext.Consumer>
      )
  }
};
