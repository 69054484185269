import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

//Material UI components
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// components
import Layout from "../components/Layout";

// Material UI
import { Theme, makeStyles } from '@material-ui/core';

// services
import Firebase, { getCurrentUser, useSession } from "../services/Firebase";

// @ts-ignore "fuck CSS typings"
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    textAlign: "center",
    padding: theme.spacing(2)
  }
}));

export interface Props extends RouteComponentProps<void> {
  user?: any,
  error: any,
  rest: any
}

function Account(props: Props) {

  const classes = useStyles();
  const user = useSession() as any;
  const [IsAdmin, setIsAdmin] = React.useState(false);

  React.useEffect(() => {
    // code to run on component mount
    let isSubscribed = true
    if (!!user && user.uid)
      getCurrentUser(user.uid).then(res => {
        if (isSubscribed) {
          const authUser = res;
          // how to check for admin user
          if (authUser && !!authUser.roles && !!authUser.roles.ADMIN) {
            setIsAdmin(true)
          }
          // this.setState({ ...res });
        }
      });
    return () => isSubscribed = false as any;
  }, [IsAdmin])

  return (
    <Layout theme={null}>
      <div className={classes.root}>
        <Toolbar>
          <Typography variant="h6" component="h1">
            Account Details
            </Typography>
        </Toolbar>
        <List>
          <ListItem>
            <ListItemText primary="Name" secondary={user.displayName} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Personal email" secondary={user.email} />
          </ListItem>
        </List>
      </div>
    </Layout>
  );
}

export default Account;
