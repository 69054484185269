import React from "react";

import { connect } from "react-redux";
import compose from "recompose/compose";
import PropTypes from "prop-types";
import classnames from "classnames";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
//import GridContainer from "components/Grid/GridContainer.jsx";
//import GridItem from "components/Grid/GridItem.jsx";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Hidden from "@material-ui/core/Hidden";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
//import CloseIcon from "@material-ui/icons/Close";
import LinearProgress from "@material-ui/core/LinearProgress";

//import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";
import headerLinksStyle from "../../assets/jss/material-kit-react/components/headerLinksStyle.jsx";

import { firestore, doUpdateBookingData, siteid } from "../../services/Firebase";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  orderText: {
    margin: "auto"
  },
  orderImage: {
    width: "100%"
  },
  custom: {
    display: "block",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0
  },
  table: {},
  tableWrapper: {
    overflowX: "auto"
  },
  tableRowName: {
    paddingRight: 0
  },
  tableRowNameIcon: {
    paddingRight: 16,
    color: theme.palette.primary.dark
  },
  tableRowNameText: {
    paddingTop: 5
  },
  listimg: {
    width: 32,
    height: "auto"
  },
  notFound: {
    paddingTop: 32
  },
  notFoundImage: {
    width: 320,
    opacity: 0.5
  },
  tableColumnWidth: {
    width: "25%",
    maxWidth: 200,
    overflow: "hidden"
  },
  adminNavLink: {
    color: "#000",
    textDecoration: "none",
    "&:hover": {
      color: "#000"
    }
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: "auto",
    marginRight: "auto",
    width: 200
  },
  ...headerLinksStyle
});
const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
});
class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true
})(TablePaginationActions);

class MakeOrder extends React.Component {
  state = {
    open: false,
    opendialog: false,
    viewcontentClose: false,
    date: new Date(),
    currentTime: "09:30",
    openEventUpdate: false,
    products: [],
    page: 0,
    rowsPerPage: 5
  };

  componentDidMount() {
    // const currentUser = Firebase.auth().currentUser;
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
    this.loadData();
  }
  componentWillUnmount() {
    this.unsubscribe();
    this.unsubscribe2();
    //this.unsubscribe4();
  }
  loadData() {
    let uid = this.props.orderDate.uid;
    const db = firestore();
    this.unsubscribe3 = new Promise(resolve => {
      Promise.all([
        (this.unsubscribe = db
          .collection(`users/${siteid}/ecommerce/structures/categories`) //categories
          .onSnapshot(querySnapshot => {
            let categories = [];
            querySnapshot.forEach(doc => {
              let category = doc.data();
              categories.push(
                Object.assign({ id: doc.id, client_id: siteid }, category)
              );
            });
            this.setState({
              categories: categories,
              categoriesSource: categories,
              ready: true
            });
          })),
        (this.unsubscribe2 = db
          .collection(`users/${siteid}/ecommerce`) //products
          .orderBy("product_id", "desc")
          .onSnapshot(querySnapshot => {
            let products = [];
            querySnapshot.forEach(doc => {
              let product = doc.data();
              // replace category name with current category index
              const { categories } = this.state;
              let catindex = categories.findIndex(
                category => category.id === product.category
              );
              product.category = catindex;

              // replace subcategory with current subcategory index
              if (catindex !== -1) {
                let subcatindex = -1;
                subcatindex = categories[catindex].subcats.findIndex(subcat => {
                  return subcat.name === product.subcategory;
                });
                product.subcategory = subcatindex;
              }

              products.push(
                Object.assign({ id: doc.id, client_id: siteid }, product)
              );
            });
            this.setState({
              products: products,
              productsSource: products
            });
          }))
      ]).then(result => {
        resolve(result);
      });
    });
  }

  viewcontent = () => {
    this.setState({ opendialog: true });
  };

  handleClose = () => {
    this.setState({ opendialog: false });
  };

  handleSnackBarClose = () => {
    this.setState({ open: false });
  };

  onChange = date => {
    this.setState({
      openEventUpdate: true,
      clickedDate: date.toString(),
      clickedDateObj: date
    });
    return this.setState({ date });
  };

  handleEventUpdateClose = () => {
    this.setState({ openEventUpdate: false, ...INITIAL_STATE });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  handleBooking = product => {
    const { currentTime } = this.state;
    let toTimestamp = strDate => Date.parse(strDate);

    // add this product_id and date clicked to order array in firebase
    let day = this.props.orderDate.orderdateObj.getDate();
    let monthIndex = this.props.orderDate.orderdateObj.getMonth();
    let year = this.props.orderDate.orderdateObj.getFullYear();
    let data = {
      timestamp: toTimestamp(
        monthIndex + 1 + "/" + day + "/" + year + " " + currentTime
      ),
      time: currentTime,
      day: day,
      month: monthIndex + 1,
      year: year,
      product_id: product.product_id,
      name: product.product_name,
      ecom_id: product.id
    };

    let uid = this.props.orderDate.uid;
    // check that this order has product that is not duplicated in this timeslot already
    // timeslot should be adjustable
    // e.g. 2hours previous or after current product booking
    this.unregister = doUpdateBookingData(siteid, data);
    this.setState({ open: true });
    this.handleClose();
  };

  handleTimeChange = e => {
    this.setState({
      currentTime: e.target.value
    });
  };
  render() {
    const { classes, orderDate } = this.props;
    const { products, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, products.length - page * rowsPerPage);
    return (
      <div>
        <div className={classes.custom} onClick={() => this.viewcontent()}>
          &nbsp;
        </div>
        <Dialog
          onClose={this.handleClose}
          open={this.state.opendialog}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle>Place Order</DialogTitle>
          <DialogContent>
            {orderDate.orderdate}
            <form className={classes.container} noValidate>
              <TextField
                id="time"
                label="Time"
                type="time"
                defaultValue="09:30"
                onChange={this.handleTimeChange}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  step: 300 // 5 min
                }}
              />
            </form>
            {this.state.ready ? (
              <div className={classes.tableWrapper}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Product</TableCell>
                      <Hidden xsDown smDown>
                        <TableCell>Price</TableCell>
                      </Hidden>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map(item => {
                        return (
                          <TableRow key={item.id}>
                            <TableCell
                              className={classnames([
                                classes.tableRowName,
                                classes.tableColumnWidth
                              ])}
                            >
                              <Grid container direction={"row"}>
                                <Grid item className={classes.tableRowNameText}>
                                  {item.imageList[0] ? (
                                    <img
                                      className={classes.listimg}
                                      src={item.imageList[0].url}
                                    />
                                  ) : (
                                    <span>no image</span>
                                  )}
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell
                              className={classnames([
                                classes.tableRowName,
                                classes.tableColumnWidth
                              ])}
                            >
                              <Grid container direction={"row"}>
                                <Grid item className={classes.tableRowNameText}>
                                  {item.product_name}
                                </Grid>
                              </Grid>
                            </TableCell>
                            <Hidden xsDown>
                              <TableCell
                                className={classnames([
                                  classes.tableRowName,
                                  classes.tableColumnWidth
                                ])}
                              >
                                £{item.price}
                              </TableCell>
                            </Hidden>
                            <TableCell>
                              <Button
                                onClick={() => this.handleBooking(item)}
                                color="primary"
                                autoFocus
                              >
                                Book
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 48 * emptyRows }}>
                        <TableCell colSpan={4} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        colSpan={3}
                        count={products.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          native: false
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActionsWrapped}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </div>
            ) : (
              <LinearProgress />
            )}
            ;
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Delete
            </Button>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleSnackBarClose}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">Order Added</span>}
        />
      </div>
    );
  }
}
const INITIAL_STATE = {
  error: null
};

MakeOrder.propTypes = {
  classes: PropTypes.object.isRequired,
  orderDate: PropTypes.any.isRequired
};

const mapStateToProps = state => ({
  orders: state.ordersState.orders
});

const mapDispatchToProps = dispatch => ({
  onUpdateOrders: orders => dispatch({ type: "ORDERS_SET", orders })
});

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MakeOrder);
