import React from "react";

import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "../GridContainer";
import GridItem from "../GridItem";
//import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";

import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";

//import { checkFile, uploadFile, overwriteFile } from "services/Firebase";
import { getEcomProduct, siteid } from "../../services/Firebase";


const styles = () => ({
  dropZone: {
    backgroundColor: "#e6e5e5",
    height: 256,
    minWidth: 256,
    display: "grid"
  },
  orderText: {
    margin: "auto"
  },
  orderImage: {
    width: "100%"
  }
});

class ViewOrder extends React.Component {
  state = {
    opendialog: false,
    viewcontentClose: false
  };

  componentDidMount() {
    // const currentUser = Firebase.auth().currentUser;
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
    this.loadData();
  }

  loadData() {
    // product image and description
    const { orderdetails } = this.props;
    const ecom_id = orderdetails.ecom_id;
    this.unsubscribe = getEcomProduct(siteid, ecom_id).then(res => {
      const product = res;
      this.setState({ product });
    });
  }
  componentWillUnmount() {
    this.unsubscribe = () => null;
  }
  viewcontent = () => {
    this.setState({ opendialog: true });
  };

  handleClose = () => {
    this.setState({ opendialog: false });
  };

  render() {
    const { classes, orderdetails } = this.props;
    const { product } = this.state;
    return (
      <div>
        <div onClick={() => this.viewcontent()}>{orderdetails.name}</div>
        <Dialog
          onClose={this.handleClose}
          open={this.state.opendialog}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle>View Order</DialogTitle>
          <DialogContent>
            {product && (typeof product.imageList[0].url !== 'undefined') ? (
              <GridContainer justify="center">
                <GridItem cs={12} sm={12} md={12}>
                  <Typography className={classes.orderText}>
                    {orderdetails.name}
                  </Typography>
                  <Typography className={classes.orderText}>
                    {product.product_desc}
                  </Typography>
                  <Typography className={classes.orderText}>
                    {orderdetails.day} / {orderdetails.month} /{" "}
                    {orderdetails.year}
                  </Typography>
                </GridItem>
                <GridItem cs={12} sm={12} md={12}>
                  <img className={classes.orderImage} src={product.imageList[0].url} />
                </GridItem>
              </GridContainer>
            ) : (
              <LinearProgress />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ViewOrder.propTypes = {
  classes: PropTypes.object.isRequired,
  orderdetails: PropTypes.object.isRequired
};

export default withStyles(styles)(ViewOrder);
