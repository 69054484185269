const INITIAL_STATE = {
  imageList: {}
};

const applySetImageList = (state, action) => ({
  ...state,
  imageList: action.imageList
});

function imageListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "IMAGE_LIST_SET": {
      return applySetImageList(state, action);
    }
    default:
      return state;
  }
}

export default imageListReducer;
