import * as React from "react";
import { RouteComponentProps, Link, withRouter } from "react-router-dom";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

export interface Props extends RouteComponentProps<void> {
  user?: any,
  error?: any,
  onClose: any,
  open: any,
  title?: any,
  content: any,
  match: any
}

function ConfirmationDialog(props: Props) {
  // class ConfirmationDialog extends React.Component {
  // const classes = useStyles();

  const handleClose = () => {
    props.onClose(false);
  };

  const handleConfirm = () => {
    props.onClose(true);
  };

  // render() {
    return (
      <Dialog
        onClose={() => handleClose()}
        aria-labelledby="Confirmation"
        aria-describedby="Confirmation"
        open={props.open}
      >
        <DialogTitle id="alert-dialog-title">{props.title || 'Confirmation'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.content || 'Are your sure you want to proceed with this operation ?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            No
          </Button>
          <Button onClick={() => handleConfirm()} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  // }
}

export default ConfirmationDialog;