import * as React from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import classnames from "classnames";
import Firebase, { doPasswordReset } from "../services/Firebase";
import * as routes from '../constants/routes';
// import { useActions } from "../actions";
// import * as fsActions from "../actions/fs";

// Material UI
import { Theme, makeStyles } from '@material-ui/core';
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
// import Icon from "@material-ui/core/Icon";
import LockOutlined from "@material-ui/icons/LockOutlined";
import Email from "@material-ui/icons/Email";

// Styles
import loginPageStyle from "../assets/jss/material-kit-react/views/loginPage";
import image from "../assets/img/bg7.jpg";

// components
import {
  GridContainer,
  GridItem,
  Card,
  CardHeader,
  CardBody,
  CustomInput,
  CardFooter,
  Button,
  Header,
  HeaderLinks
} from "../components";
// import { SignUpLink } from './SignUp';
// import { PasswordForgetLink } from './PasswordForget';

// @ts-ignore "fuck CSS typings"
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 20,
    [theme.breakpoints.down("md")]: {
      paddingTop: 50,
      paddingLeft: 15,
      paddingRight: 15,
    }
  },
  ...loginPageStyle
}));

export interface Props extends RouteComponentProps<void> {
  user: any,
  error: any,
  rest: any
}

function PasswordForgetForm(props: Props) {
  
  const { ...rest } = props;
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState();
  // const [cardAnimaton, setCardAnimaton] = React.useState("");
  const cardAnimaton = "";
  const [history, setHistory] = React.useState(props.history);
  // const [user, setUser] = React.useState(props.user);
  const user = props.user;
  const _isMounted = false;

  const isInvalid = email === "";

  /*
  const handleClose = () => {
    setOpen(false);
  };
  */
  /*
  const handleLogin = () => {
    setOpen(true);
  };
  */
  const onSignIn = () => {
    if (user) {
      history.push("/");
      return setHistory(history);
    };

    const provider = new Firebase.auth.GoogleAuthProvider();
    _isMounted &&
      Firebase.auth()
        .signInWithPopup(provider)
        .then();
  }

  const onSubmit = (event: any) => {

    doPasswordReset(email)
        .then(() => {
            setEmail("");
        })
        .catch(error => {
          setError(error);
        });

    event.preventDefault();
  };
  // following might be used in services instead of current method. I'm still not sure about subscribing etc...
  /*
    (async () => {
      // const compared = await compareHashPassword(pw, originPw);
      this.unregister = await doAddUserEcommerceSubCatData(
        uid,
        category,
        newsubcat
      ).then(res => {
        console.log("DONE!!!");
        this.setState({ open: true, categorymsg: res }); // snackbar message
      });
    })();
  */

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Conway Alphonso | Login"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >

        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form} onSubmit={onSubmit}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4 className={classes.defaultFont}>Forgotten Password</h4>
                    
                  </CardHeader>
                  
                  <CardBody>
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      name="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "email",
                        onChange: (e: any) => setEmail(e.target.value),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button
                      simple
                      color="primary"
                      size="lg"
                      disabled={isInvalid}
                      type="submit"
                    >
                      Reset My Password
                      </Button>
                  </CardFooter>
                </form>

                <br />
                <br />
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
const PasswordForgetLink = () =>
    <p>
        <Link to={routes.PASSWORD_FORGET}>Forgot Password?</Link>
    </p>

export default PasswordForgetForm;

export {
    PasswordForgetLink,
};
//export default LoginPage;