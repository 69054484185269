import { Button, Grid, Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import TodoTable from "../components";
import TodoDialog from "../components/TodoDialog";
import Layout from "../components/Layout";
import FilesTable from "../components/FilesTable";
// import { withUserContext } from "../contexts/User";

interface Props extends RouteComponentProps<void> { }

function FilesPage(props: Props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddTodo = () => {
    setOpen(true);
  };

  return (
    <Layout theme={null}>
      <FilesTable />
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 20,
    [theme.breakpoints.down("md")]: {
      paddingTop: 50,
      paddingLeft: 15,
      paddingRight: 15,
    },
  },

  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },

  button: {
    marginBottom: 15,
  },
}));

export default FilesPage;
