import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Layout from "../components/Layout";
import New from "../components/Poll/New";

interface Props extends RouteComponentProps<void> { }

function NewPollPage(props: Props) {
  
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddTodo = () => {
    setOpen(true);
  };

  return (
    <Layout theme={null}>
      <New />
    </Layout>
  );
}


export default NewPollPage;
