import React from "react";

import PropTypes from "prop-types";

//import Button from "@material-ui/core/Button";
import InputAdornment from '@material-ui/core/InputAdornment'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CustomInput from '../CustomInput'
import LinearProgress from "@material-ui/core/LinearProgress";

import GridContainer from '../GridContainer'
import GridItem from '../GridItem'
import Button from '../Button'


import Typography from "@material-ui/core/Typography";
import Domain from '@material-ui/icons/Domain'
import Build from '@material-ui/icons/Build'
import FiberNew from '@material-ui/icons/FiberNew'

import { withStyles } from "@material-ui/core/styles";

import Dropzone from "react-dropzone";

import {
  checkEcommerceFile,
  uploadEcommerceFile,
  overwriteEcommerceFile
} from "../../services/Firebase";

import ConfirmationDialog from "../ConfirmationDialog";

const styles = () => ({
  dropZone: {
    backgroundColor: "#e6e5e5",
    height: 256,
    minWidth: 256,
    display: "grid"
  },
  dropZoneText: {
    margin: "auto",
    textAlign: "center"
  }
});

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
})

class EcommerceCustomFieldDialog extends React.Component {
  state = {
    ...INITIAL_STATE,
    file: null,
    filled: false,
    uploading: false,
    openConfirmation: false,
    fileExisting: null,
  };

  handleCustomField = eventForm => {
    console.log('SAVED!!!!!');
    const { authUser } = this.props;

    let {
      customName,
      customValue
    } = this.state;

    eventForm.preventDefault();
    this.props.customFieldData({ customName, customValue });
    this.handleClose();

  }

  handleClose = () => {
    this.setState({ uploading: false });
    this.props.onClose();
  };

  handleConfirm = confirmed => {
    this.setState({ openConfirmation: false });
    if (confirmed) {
      overwriteEcommerceFile(this.state.file)
        .then(res => {
          // console.log("this is the overwrite file to send back to imageFile/handleImages", this.state.file);
          //const id = res[1].id;

          let {
            customName,
            customValue
          } = this.state;

          // this.props.customFieldData({ customName, customValue });
          // this.handleClose();
        })
        .catch(() => {
          this.handleClose();
        });
    } else {
      this.handleClose();
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog
          onClose={this.props.handleClose}
          aria-labelledby="Upload"
          aria-describedby="Upload"
          open={this.props.open}
        >
          
            <DialogTitle>Add Custom Field</DialogTitle>
            <DialogContent>
              {this.state.uploading && <LinearProgress />}


              <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <CustomInput
                    labelText="Custom Field Name..."
                    id="customName"
                    name="customName"
                    autoComplete="customName"
                    autoFocus
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'text',
                      value: this.state.customName,
                      onChange: event => this.setState(byPropKey('customName', event.target.value)),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Build className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <CustomInput
                    labelText="Custom Field Value..."
                    id="customValue"
                    name="customValue"
                    autoComplete="customValue"
                    autoFocus
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'text',
                      value: this.state.customValue,
                      onChange: event => this.setState(byPropKey('customValue', event.target.value)),
                      endAdornment: (
                        <InputAdornment position="end">
                          <FiberNew className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
              </GridContainer>

            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={this.handleCustomField}>
                Save
            </Button>
              <Button onClick={this.handleClose} color="primary">
                Discard
            </Button>
            </DialogActions>
          
        </Dialog>
        <ConfirmationDialog
          open={this.state.openConfirmation}
          content={`This file exists, do you want to overwrite it?`}
          onClose={this.handleConfirm}
        />
      </div>
    );
  }
}

const INITIAL_STATE = {
  id: undefined,
  product_id: '',
  customName: '',
  customValue: '',
  error: null,
}

EcommerceCustomFieldDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  customFieldData: PropTypes.func.isRequired,
  handleClose: PropTypes.func
};

export default withStyles(styles)(EcommerceCustomFieldDialog);
