export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const LANDING = '/';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const ADMIN2 = '/admin2';
export const PASSWORD_FORGET = '/pw-forget';
export const PASSWORD_FORGET2 = '/pw-forget2';
export const DASHBOARD = '/conq';
export const NEWARTICLE = '/conq/newarticle';
export const ARTICLEADMIN = '/conq/articleadmin';
export const USERADMIN = '/conq/useradmin';