import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";

import PropTypes from "prop-types";
// @material-ui/core components
import Calendar from "react-calendar";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import headerLinksStyle from "../../assets/jss/material-kit-react/components/headerLinksStyle.jsx";

// @material-ui/icons
// core components
import ViewOrder from "../ViewOrder/ViewOrder.jsx";
import MakeOrder from "../MakeOrder/MakeOrder.jsx";
import GridContainer from "../GridContainer";
import GridItem from "../GridItem";
import Card from "../Card";
import Firebase, { doUpdateBookingData, firestore, siteid } from "../../services/Firebase";

import "../../assets/scss/booking-calendar.css";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  table: {},
  tableWrapper: {
    overflowX: "auto"
  },
  tableRowName: {
    paddingRight: 0
  },
  tableRowNameIcon: {
    paddingRight: 16,
    color: theme.palette.primary.dark
  },
  tableRowNameText: {
    paddingTop: 5
  },
  listimg: {
    width: 32,
    height: "auto"
  },
  notFound: {
    paddingTop: 32
  },
  notFoundImage: {
    width: 320,
    opacity: 0.5
  },
  tableColumnWidth: {
    width: "25%",
    maxWidth: 200,
    overflow: "hidden"
  },
  adminNavLink: {
    color: "#000",
    textDecoration: "none",
    "&:hover": {
      color: "#000"
    }
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: "auto",
    marginRight: "auto",
    width: 200
  },
  ...headerLinksStyle
});

const OrderList = (orders, orderdate, orderdateObj) => {
  return (
    <div>
      <MakeOrder orderDate={orders} className="custom" />
      {/*
      <div onClick={() => doMakeOrder(orders)} className="custom">
        &nbsp;
      </div>
      */}
      <ul>
        {orders.orders.map((item, key) => {
          return (
            <li key={key}>
              <ViewOrder uid={orders.uid} orderdetails={item} />
            </li>
          ); //change this to name once the database ahas been changed
        })}
      </ul>
    </div>
  );
};

class Booking extends React.Component {
  state = {
    cardAnimaton: "cardHidden",
    date: new Date(),
    currentTime: "09:30",
    openEventUpdate: false,
    clickedDate: "",
    products: [],
    page: 0,
    rowsPerPage: 5
  };

  componentDidMount() {
    // const currentUser = Firebase.auth().currentUser;
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
    this.loadData();
  }

  componentDidUpdate(prevProps) {

    const now = Object.entries(this.props);
    const added = now.filter(([key, val]) => {
      if (prevProps[key] === undefined) return true;
      if (prevProps[key] !== val) {
        console.log(`${key}
          - ${JSON.stringify(val)}
          + ${JSON.stringify(prevProps[key])}`);
      }
      return false;
    });
    added.forEach(([key, val]) =>
      console.log(`${key}
          + ${JSON.stringify(val)}`)
    );
  }
  componentWillUnmount() {
    this.unsubscribe();
    this.unsubscribe2();
    this.unsubscribe4();
  }
  loadData() {
    let currentUser;
    if (this.props.currentUser === undefined) {
      currentUser = Firebase.auth().currentUser;
    } else {
      this.props.currentUser.uid = this.props.currentUser.id;
      currentUser = this.props.currentUser;
    }

    /**
     * 'uid' is really the 'siteid'
     */
    /*
    const { authUser } = this.props;
    let uid;
    if (this.props.currentUser === undefined) uid = authUser.uid;
    else uid = this.props.currentUser.id;
    */

    const db = firestore();
    this.unsubscribe3 = new Promise(resolve => {
      Promise.all([
        (this.unsubscribe = db
          .collection(`users/${siteid}/ecommerce/structures/categories`) //categories
          .onSnapshot(querySnapshot => {
            let categories = [];
            querySnapshot.forEach(doc => {
              let category = doc.data();
              categories.push(
                Object.assign({ id: doc.id, client_id: siteid }, category)
              );
            });
            this.setState({
              categories: categories,
              categoriesSource: categories,
              ready: true
            });
          })),
        (this.unsubscribe2 = db
          .collection(`users/${siteid}/ecommerce`) //products
          .orderBy("product_id", "desc")
          .onSnapshot(querySnapshot => {
            let products = [];
            querySnapshot.forEach(doc => {
              let product = doc.data();
              // replace category name with current category index
              const { categories } = this.state;
              let catindex = categories.findIndex(
                category => category.id === product.category
              );
              product.category = catindex;

              // replace subcategory with current subcategory index
              if (catindex !== -1) {
                let subcatindex = -1;
                subcatindex = categories[catindex].subcats.findIndex(subcat => {
                  return subcat.name === product.subcategory;
                });
                product.subcategory = subcatindex;
              }

              products.push(
                Object.assign(
                  { id: doc.id, client_id: siteid },
                  product
                )
              );
            });
            this.setState({
              products: products,
              productsSource: products
              //  ready: true
            });
          })),
        (this.unsubscribe4 = db
          .collection(`users/${siteid}/ecommerce/structures/orders`) //orders
          .onSnapshot(querySnapshot => {
            let orders = [];
            querySnapshot.forEach(doc => {
              let order = doc.data();
              orders.push(Object.assign({ id: doc.id, client_id: siteid }, order));
            });
            this.setState({
              orders: orders,
              ordersSource: orders,
              ready: true
            });
          }))
      ]).then(result => {
        resolve(result);
      });
    });
  }

  tileContent = ({ date, view }) => {
    /**
     * 'uid' is really 'siteid'
     */
    /*
    let uid, currentUser;
    if (this.props.currentUser === undefined) {
      currentUser = Firebase.auth().currentUser;
      uid = currentUser.uid;
    } else {
      uid = this.props.currentUser.id;
    }
    */

    let orderdate = date.toString();
    let orderdateObj = date;
    let orderCount = this.getOrders(date.getMonth(), date.getDate());
    //console.log("orderdtae", orderdate);
    if (orderCount.length) {
      //console.log("hits orderCount", orderCount);
      if (view === "month" && orderCount) {
        return (
          <OrderList
            uid={siteid}
            orders={orderCount}
            orderdate={orderdate}
            orderdateObj={orderdateObj}
          />
        );
      } else {
        return null;
      }
    }
    return (
      <OrderList
        uid={siteid}
        orders={orderCount}
        orderdate={orderdate}
        orderdateObj={orderdateObj}
      />
    );
  };

  getOrders = (theMonth, theDate) =>
    this.state.orders.filter(order =>
      order.month === theMonth + 1 && order.day === theDate ? order : null
    );

  onChange = date => {
    this.setState({
      openEventUpdate: true,
      clickedDate: date.toString(),
      clickedDateObj: date
    });
    return this.setState({ date });
  };

  handleEventUpdateClose = () => {
    this.setState({ openEventUpdate: false, ...INITIAL_STATE });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  handleBooking = product => {
    // const { authUser } = this.props;
    const { clickedDateObj, currentTime } = this.state;
    let toTimestamp = strDate => Date.parse(strDate);

    // add this product_id and date clicked to order array in firebase
    let day = clickedDateObj.getDate();
    let monthIndex = clickedDateObj.getMonth();
    let year = clickedDateObj.getFullYear();
    let data = {
      timestamp: toTimestamp(
        monthIndex + 1 + "/" + day + "/" + year + " " + currentTime
      ),
      time: currentTime,
      day: day,
      month: monthIndex + 1,
      year: year,
      product_id: product.product_id,
      name: product.product_name,
      ecom_id: product.id
    };

    /*
    let uid;
    if (this.props.currentUser) uid = this.props.currentUser.uid;
    else uid = authUser.uid;
    */

    this.unregister = doUpdateBookingData(siteid, data);
    this.setState({ open: true });
    this.handleEventUpdateClose();
  };

  handleTimeChange = e => {
    this.setState({
      currentTime: e.target.value
    });
  };

  render() {
    const { classes } = this.props;
    const { orders, products, rowsPerPage, clickedDate, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, products.length - page * rowsPerPage);

    const tileContent = ({ date, view }) => {
      let uid, currentUser;
      if (this.props.currentUser === undefined) {
        currentUser = Firebase.auth().currentUser;
        uid = currentUser.uid;
      } else {
        uid = this.props.currentUser.id;
      }

      let orderdate = date.toString();
      let orderdateObj = date;
      let orderCount = this.getOrders(date.getMonth(), date.getDate());
      if (orderCount.length) {
        if (view === "month" && orderCount) {
          return (
            <OrderList
              uid={uid}
              orders={orderCount}
              orderdate={orderdate}
              orderdateObj={orderdateObj}
            />
          );
        } else {
          return null;
        }
      }
      return (
        <OrderList
          uid={uid}
          orders={orderCount}
          orderdate={orderdate}
          orderdateObj={orderdateObj}
        />
      );
    };

    return (
      <div>
        <GridContainer justify="center">
          <GridItem cs={12} sm={12} md={8}>
            <Card className={classes[this.state.cardAnimaton]}>
              {orders ? (
                <Calendar
                  tileContent={tileContent}
                  className="order-calendar"
                />
              ) : (
                <LinearProgress />
              )}
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
const INITIAL_STATE = {
  error: null
};
Booking.propTypes = {
  history: PropTypes.object,
  authUser: PropTypes.object,
  classes: PropTypes.object,
  rest: PropTypes.object,
  newImageList: PropTypes.object,
  items: PropTypes.array,
  currentUser: PropTypes.object,
  products: PropTypes.object,
  categories: PropTypes.object,
  view: PropTypes.func
};

const mapStateToProps = state => ({
  users: state.userState.users,
  authUser: state.sessionState.authUser,
  newImageList: state.imageListState.imageList,
  products: state.productsState.products,
  categories: state.categoriesState.categories,
  imageToggle: state.imageToggleState.imageToggle,
  editArticle: state.editArticleState.editArticle
});

const mapDispatchToProps = dispatch => ({
  onEditArticle: editArticle => dispatch({ type: "EDIT_ARTICLE", editArticle }),
  onImageList: imageList => dispatch({ type: "IMAGE_LIST_SET", imageList }),
  onImageToggle: imageToggle => dispatch({ type: "IMAGE_TOGGLE", imageToggle }),
  onUpdateProducts: products => dispatch({ type: "PRODUCTS_SET", products }),
  onUpdateCategories: categories =>
    dispatch({ type: "CATEGORIES_SET", categories })
});

// const authCondition = authUser => !!authUser && authUser.role === "ADMIN";
export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Booking);
