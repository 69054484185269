import { Button, Grid, Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import TodoTable from "../components";
import TodoDialog from "../components/TodoDialog";
import Layout from "../components/Layout";
import { EcommerceAdmin } from "../components";
// import { withUserContext } from "../contexts/User";

// services
import Firebase, { getCurrentUser, useSession } from '../services/Firebase'

interface Props extends RouteComponentProps<void> { }

function EcommerceAdminPage(props: Props) {
  
  const user = useSession()
  const [IsAdmin, setIsAdmin] = React.useState(false)
  const [IsEcommerce, setIsEcommerce] = React.useState(false)
  const [open, setOpen] = React.useState(false);


  React.useEffect(() => {
    // code to run on component mount
    let isSubscribed = true
    if (!!user && user.uid) {
      getCurrentUser(user.uid).then(res => {
        if (isSubscribed) {
          const authUser = res
          // how to check for admin user
          if (authUser && !!authUser.roles && !!authUser.roles.ADMIN) {
            setIsEcommerce(true)
          }
          if (authUser && !!authUser.roles && !!authUser.roles.ECOMMERCE) {
            setIsEcommerce(true)
          }
        }
        // this.setState({ ...res });
      })
    }
    return () => (isSubscribed = false as any)
    /*
    Firebase.auth().onAuthStateChanged(user => {
      // console.log("reloaded user", user);
      this.setState({ user: user, ready: true });

      if (!!user && user.uid)
        getCurrentUser(user.uid).then(res => {
          const authUser = res;
          // how to chack for admin user
          authUser && !!authUser.roles && !!authUser.roles.ADMIN
            ? this.setState({ IsAdmin: true })
            : null;
          this.setState({ ...res });
        });
    });
    */
  }, [])

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddTodo = () => {
    setOpen(true);
  };

  return (
    <div>
      {IsEcommerce && 
        <Layout theme={null}>
          <EcommerceAdmin />
        </Layout>
      }
    </div>
  );
}


export default EcommerceAdminPage;
