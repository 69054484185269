import React from "react";
import { RouteComponentProps } from "react-router-dom";

import Layout from "../components/Layout";
// import FilesTable from "../components/FilesTable";
// import HomeTabs from "../components/HomeTabs/HomeTabs.jsx";
import HomePage from "./HomePage";

interface Props extends RouteComponentProps<void> {
  rest:any,
  history:any
 }

function Admin(props: Props) {
  const { history } = props;
  // render() {
    return (
      <Layout theme={null}>
        ADMIN DASHBOARD
      </Layout>
    );
  // }
}

export default Admin;
