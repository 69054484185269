import React, { Component, Fragment } from "react";
import ReactAvatarEditor from 'react-avatar-editor';
import Preview from '../Preview.jsx';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import update from "immutability-helper";
//import { DragDropContext } from "react-dnd";
//import HTML5Backend from "react-dnd-html5-backend";
import CustomFieldListItem from "./CustomFieldListItem";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import InputAdornment from '@material-ui/core/InputAdornment';
import FiberNew from '@material-ui/icons/FiberNew'
import Build from '@material-ui/icons/Build'
import GridContainer from '../GridContainer'
import GridItem from '../GridItem'
import ConfirmationDialog from "../ConfirmationDialog";
import CustomInput from '../CustomInput'
import dashboardStyle from "../../assets/jss/material-kit-react/views/dashboardStyle.jsx";
import basicsStyle from "../../assets/jss/material-kit-react/views/componentsSections/basicsStyle.jsx";
import avatarimage from "../avatar.jpg";

import Firebase, {
  gcfn,
  siteid,
  uploadEcommerceCroppedImage,
  getFileURL,
  checkEcommerceFile,
  uploadEcommerceFile,
  overwriteEcommerceFile
} from '../../services/Firebase'

const style = {
  ...dashboardStyle,
  ...basicsStyle,
  formControl: {
    width: "100%"
  },
  listimg: {
    width: 32,
    height: "auto"
  },
  deleteIcon: {
    position: "relative",
    top: 8,
    "&:hover": {
      cursor: "pointer"
    }
  },
  iconContainer: {
    flex: "0 0 auto",
    width: 48,
    height: 48,
    padding: 0
  },
  imageview: {
    maxWidth: 300
  },
  wrapListText: {
    wordWrap: "break-word",
    overflow: "hidden",
    marginLeft: 5,
    marginRight: 25,
    width: "50%",
  }
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
})

class CustomFieldList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: false,
      openConfirmation: false,
      openImage: false,
      openCustom: false,
      currentImage: null,
      items:
        props.product.customFieldList &&
        props.product.customFieldList.map((item,i) => ({
          id: i,
          customName: item.customName,
          customValue: item.customValue,
        })),
      image: avatarimage,
      allowZoomOut: false,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      rotate: 0,
      borderRadius: 0,
      preview: null,
      width: 400,
      height: 400,
    };

    // this.handleOnClick = this.handleOnClick.bind(this);
    this.moveListItem = this.moveListItem.bind(this);
    this.handleOnToggle = this.handleOnToggle.bind(this);
  }

  // needs some kind of timer function to eliminate the multiple calling tht happens here
  componentDidUpdate(prevProps) {
    if (this.props.product !== prevProps.product) {
      this.setState({ items: this.props.product.customFieldList });
    }
  }

  //handleOnClick(item) {
  // You may pass handle on click event to props
  // console.log(item);
  //}

  moveListItem(dragIndex, hoverIndex) {
    const { items } = this.state;
    const dragitem = items[dragIndex];

    this.setState(
      update(this.state, {
        items: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragitem]]
        }
      })
    );
    // You may pass the state to props
    this.props.onImageList(this.state);
  }

  handleOnToggle(index) {
    let currentItems = this.state.items;
    currentItems[index].live = !currentItems[index].live;
    this.setState({ items: currentItems, ...this.state });
    //this.props.onImageToggle(id);
    this.props.onImageList(this.state);
  }

  deleteImage = index => () => {
    let { items } = this.state;
    items.splice(index, 1);
    this.setState({ items: items });
  };

  openCustomFieldDialog = (id, name, value) => {
    //console.log('id', id);
    this.setState({ openCustom: true, customName: name, customValue: value, currentIndex: id });
    // get image from firebase function so it bypasses cross domain issues
    // 1 try sending image url to node server and then load image a 64blob thingy
    /*
    const chatboutImage = gcfn.httpsCallable('chatboutImage');
    let imageToCrop = null;
    chatboutImage({filename: filename, siteid: siteid}).then((result) => {
      imageToCrop = `data:image/jpg;base64,${result.data.encodedimage}`;
      this.setState({ openImage: true, currentImage: filename, image: imageToCrop });
    }).catch((error) => {
      // Getting the Error details.
      // var code = error.code;
      // var message = error.message;
      // var details = error.details;
      console.log(error);
    });
    */
  };

  handleImageClose = () => {
    this.setState({ openImage: false });
  };

  deleteImage = index => {
    let { items } = this.state;
    items.splice(index, 1);
    this.setState({ items: items });
    this.props.onCustomFieldList(this.state);
  };

  handleCustomField = () => {
    let {
      customName,
      customValue,
      currentIndex,
      items
    } = this.state;

    //eventForm.preventDefault();
    //console.log('customName, customValue', customName, customValue);
    //console.log('currentIndex', currentIndex);
    //console.log('items', items);
    items[currentIndex].customValue = customValue;
    items[currentIndex].customName = customName;
    this.props.customFieldData(items);
    this.setState({ openCustom: false, items: items });
  }

  handleCustomClose = () => {
    this.setState({ openCustom: false });
  }

  onClickSaveCrop = () => {
    if (this.editor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      //const canvas = this.editor.getImage()

      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      //const canvasScaled = this.editor.getImageScaledToCanvas()
      //console.log('canvasScaled', canvasScaled);

      const image = this.editor.getImageScaledToCanvas().toDataURL('image/png');
      const stringLength = image.split(',')[1].length;
      const sizeInBytes = 4 * Math.ceil((stringLength / 3)) * 0.5624896334383812;
      const sizeInKb = sizeInBytes / 1000;
      const filename = 'cropped_' + this.state.currentImage.replace(".jpg", ".png");
      filename.replace(".jpeg", ".png");

      uploadEcommerceCroppedImage(image.split(',')[1], filename, sizeInKb).then(res => {
        this.props.imageFile(filename, res[1].id); // send this back to EcoAdmin to be placed in image list ready to be saved
        this.setState({ openImage: false });
      });
    }
  }

  handleConfirm = confirmed => {
    this.setState({ openConfirmation: false });
    if (confirmed) {
      overwriteEcommerceFile(this.state.file)
        .then(res => {
          // console.log("this is the overwrite file to send back to imageFile/handleImages", this.state.file);
          const id = res[1].id;
          this.props.imageFile(this.state.file.name, id);
          this.handleClose();
        })
        .catch(() => {
          this.handleClose();
        });
    } else {
      this.handleClose();
    }
  };

  //////////////////
  handleNewImage = e => {
    this.setState({ image: e.target.files[0] })
  }

  handleSave = data => {
    //let img = new Image();
    //let timestamp = new Date().getTime();
    //img.setAttribute('crossOrigin', 'anonymous');
    //img.src = this.currentImage + '?' + timestamp;

    let img = this.editor.getImageScaledToCanvas().toDataURL();
    const rect = this.editor.getCroppingRect()

    this.setState({
      preview: {
        img,
        rect,
        scale: this.state.scale,
        width: this.state.width,
        height: this.state.height,
        borderRadius: this.state.borderRadius,
      },
    })
  }

  handleScale = e => {
    const scale = parseFloat(e.target.value)
    this.setState({ scale })
  }

  handleAllowZoomOut = ({ target: { checked: allowZoomOut } }) => {
    this.setState({ allowZoomOut })
  }

  rotateLeft = e => {
    e.preventDefault()

    this.setState({
      rotate: this.state.rotate - 90,
    })
  }

  rotateRight = e => {
    e.preventDefault()
    this.setState({
      rotate: this.state.rotate + 90,
    })
  }

  handleBorderRadius = e => {
    const borderRadius = parseInt(e.target.value)
    this.setState({ borderRadius })
  }

  handleXPosition = e => {
    const x = parseFloat(e.target.value)
    this.setState({ position: { ...this.state.position, x } })
  }

  handleYPosition = e => {
    const y = parseFloat(e.target.value)
    this.setState({ position: { ...this.state.position, y } })
  }

  handleWidth = e => {
    const width = parseInt(e.target.value)
    this.setState({ width })
  }

  handleHeight = e => {
    const height = parseInt(e.target.value)
    this.setState({ height })
  }

  logCallback(e) {
    // eslint-disable-next-line
    console.log('callback', e)
  }

  setEditorRef = editor => {
    if (editor) this.editor = editor
  }

  handlePositionChange = position => {
    this.setState({ position })
  }

  handleDrop = acceptedFiles => {
    this.setState({ image: acceptedFiles[0] })
  }
  ////////////////////


  render() {
    const { classes, id } = this.props;
    const { items } = this.state;
    //console.log('items', items);
    //debugger;
    return (
      <Fragment>
        <List dense className={classes.root}>
          {items && items.map((item, i) => (
            <CustomFieldListItem
              key={i}
              index={i}
              id={item.id}
              field={item.customName || ""}
              value={item.customValue || ""}
              live={item.live}
              url={item.url}
              classes={classes}
              liveUpdate={this.handleOnToggle}
              moveListItem={this.moveListItem}
              deleteImage={this.deleteImage}
              openCustomFieldDialog={this.openCustomFieldDialog}
            />
          ))}
        </List>

        <Dialog
          onClose={this.state.handleClose}
          aria-labelledby="Upload"
          aria-describedby="Upload"
          open={this.state.openCustom}
        >

          <DialogTitle>Edit Custom Field</DialogTitle>
          <DialogContent>
            {this.state.uploading && <LinearProgress />}

            <GridContainer>
              <GridItem xs={12} sm={6} md={6} lg={6}>
                <CustomInput
                  labelText="Custom Field Name..."
                  id="customName"
                  name="customName"
                  autoComplete="customName"
                  autoFocus
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    value: this.state.customName,
                    onChange: event => this.setState(byPropKey('customName', event.target.value)),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Build className={classes.inputIconsColor} />
                      </InputAdornment>
                    ),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={6}>
                <CustomInput
                  labelText="Custom Field Value..."
                  id="customValue"
                  name="customValue"
                  autoComplete="customValue"
                  autoFocus
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    value: this.state.customValue,
                    onChange: event => this.setState(byPropKey('customValue', event.target.value)),
                    endAdornment: (
                      <InputAdornment position="end">
                        <FiberNew className={classes.inputIconsColor} />
                      </InputAdornment>
                    ),
                  }}
                />
              </GridItem>
            </GridContainer>

          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.handleCustomField}>
              Save
            </Button>
            <Button onClick={this.handleCustomClose} color="primary">
              Discard
            </Button>
          </DialogActions>

        </Dialog>
      </Fragment>
    );
  }
}

CustomFieldList.propTypes = {
  classes: PropTypes.object,
  items: PropTypes.object,
  onCustomFieldList: PropTypes.func
};

const mapStateToProps = state => ({
  customFieldList: state.customFieldListState.customFieldList,
  //imageList: state.imageListState.imageList,
  imageToggle: state.imageToggleState.imageToggle
});

const mapDispatchToProps = dispatch => ({
  onCustomFieldList: customFieldList => dispatch({ type: "CUSTOM_FIELD_LIST_SET", customFieldList }),
  //onImageList: imageList => dispatch({ type: "IMAGE_LIST_SET", imageList }),
  onImageToggle: imageToggle => dispatch({ type: "IMAGE_TOGGLE", imageToggle })
});

// const authCondition = authUser => !!authUser && authUser.role === "ADMIN";

export default compose(
  //withAuthorization(authCondition),
  withStyles(style),
  //DragDropContext(HTML5Backend),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CustomFieldList);
