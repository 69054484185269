import {
  // AppBar, 
  Badge,
  Divider,
  Drawer as DrawerMui,
  // Hidden, 
  // IconButton, 
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  // Toolbar, 
  // Typography, 
  withWidth
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
// import { isWidthUp, WithWidth } from "@material-ui/core/withWidth";
import { WithWidth } from "@material-ui/core/withWidth";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import HomeIcon from "@material-ui/icons/Home";
// import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/styles";
import firebase from "firebase/app";
import * as React from "react";
import { connect } from "react-redux";
import { Route, RouteComponentProps, Router, Switch, Redirect } from "react-router-dom";
import { history } from "./configureStore";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Todo } from "./model/model";

// Pages
import Home from "./pages/Home";
import Splash from "./pages/Splash";
import TodoPage from "./pages/TodoPage";
import FilesPage from "./pages/FilesPage";
import LoginPage from "./pages/LoginPage";
import Admin from "./pages/Admin";
import Account from "./pages/Account";
import PasswordForgetForm from './pages/PasswordForget3';
import SignUpPage from './pages/SignUpx';

import EcommerceAdminPage from "./pages/EcommerceAdminPage";
//import ArticleAdminPage from "./pages/EcommerceAdminPage";
import BookingAdminPage from "./pages/BookingAdminPage";
import SurveyAdminPage from "./pages/SurveyAdminPage";
import SiteAdminPage from "./pages/SiteAdminPage";

// Poll
import Poll from "./pages/Poll";
import RecoverPollPage from './pages/RecoverPollPage';
import NewPollPage from './pages/NewPollPage';
import UpdatePollPage from './pages/UpdatePollPage';
import PollPage from './pages/PollPage';
import NominationsPage from './pages/NominationsPage';
import NotFound from './components/Poll/NotFound';

// State Management
import { RootState } from "./reducers/index";
import withRoot from "./withRoot";
import { useAuthState } from "react-firebase-hooks/auth";
import { userContext } from "./contexts/User";

interface PrivateRouteProps {
  component: any;
  exact?: boolean;
  path?: string;
  match?: any;
  location?: any;
  history?: any;
}

const PrivateRoute = ({
  component: Component,
  ...rest
}: PrivateRouteProps) => {
  const user = firebase.auth().currentUser;
  // const [match, params] = useRoute(path);

  if (!user) {
    return <Redirect to="login" />;
  }

  return (
    <Route {...rest} render={(props) => (
      <Component />
    )} />);
};

function Routes() {
  const classes = useStyles({});
  const [authuser, initialising, error] = useAuthState(firebase.auth());
  const user: any = authuser;

  if (error) {
    console.log("error", error);
  }

  if (initialising) {
    return (
      <LinearProgress />
    );
  }

  return (
    <div className={classes.content}>
      <userContext.Provider value={{
        user: user,
        initialising
      }}>
        <Switch>
          <Route exact={true} path="/" component={Home} />
          <Route exact={true} path="/home" component={Home} />
          <Route exact={true} path="/splash" component={Splash} />
          <Route exact={true} path="/login" component={LoginPage} />
          <Route exact={true} path={"/pw-forget"} component={PasswordForgetForm} />
          <Route exact={true} path={"/signup"} component={SignUpPage} />

          <PrivateRoute exact={true} path="/account" component={Account} />
          <PrivateRoute exact={true} path="/polls" component={Poll} />

          <PrivateRoute exact={true} path="/polls/recover" component={RecoverPollPage} />
          <PrivateRoute exact={true} path="/polls/new" component={NewPollPage} />
          <PrivateRoute exact={true} path="/polls/update/:pollId" component={UpdatePollPage} />
          <PrivateRoute exact={true} path="/polls/poll/:pollId" component={PollPage} />


          <PrivateRoute exact={true} path="/todo" component={TodoPage} />
          <PrivateRoute exact={true} path="/files" component={FilesPage} />
          <PrivateRoute exact={true} path="/admin" component={Admin} />
          <PrivateRoute exact={true} path="/bookingadmin" component={BookingAdminPage} />
          <PrivateRoute exact={true} path="/nominations" component={NominationsPage} />
          <PrivateRoute exact={true} path="/surveyadmin" component={SurveyAdminPage} />
          <PrivateRoute exact={true} path="/ecommerce" component={EcommerceAdminPage} />
          <PrivateRoute exact={true} path="/sitemanagement" component={SiteAdminPage} />
          <Redirect to={{ pathname: "/" }} />
        </Switch>
      </userContext.Provider>
    </div>
  );
}

interface Props extends RouteComponentProps<void>, WithWidth {
  todoList: Todo[];
}

function App(props?: Props) {
  const classes = useStyles({});
  const [mobileOpen, setMobileOpen] = React.useState(true);
  // const { initialising, user } = useAuthState(firebase.auth());

  if (!props) {
    return null;
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  

  return (
    <Router history={history}>
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <Routes />
        </div>
      </div>
    </Router>
  );
}

function TodoIcon(props: { todoList: Todo[] }) {
  let uncompletedTodos = props.todoList.filter(t => t.completed === false);

  if (uncompletedTodos.length > 0) {
    return (
      <Badge color="secondary" badgeContent={uncompletedTodos.length}>
        <FormatListNumberedIcon />
      </Badge>
    );
  } else {
    return <FormatListNumberedIcon />;
  }
}

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    zIndex: 1,
    overflow: "hidden",
  },
  appFrame: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    position: "absolute",
  },
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  drawerHeader: theme.mixins.toolbar,
  drawerPaper: {
    width: 250,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      position: "relative",
      height: "100%",
    },
  },
  content: {
    backgroundColor: theme.palette.background.default,
    width: "100%",
    height: "calc(100% - 56px)",
    /*
    marginTop: 56,
    
    [theme.breakpoints.up("sm")]: {
      height: "calc(100% - 64px)",
      width: "calc(100% - 240px)",
      marginTop: 64,
      marginLeft: 240,
    },
    */
  },
}));

function mapStateToProps(state: RootState) {
  return {
    todoList: state.todoList,
  };
}

export default connect(mapStateToProps)(withRoot(withWidth()(App)));
