import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { green } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import Button from '../Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import GridContainer from '../GridContainer'
import GridItem from '../GridItem'

// @material-ui/icons
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import SitesTableToolbar from "../SitesTableToolbar"
// import AdminProductTableMenu from "./AdminProductTableMenu.jsx";

import LinearProgress from '@material-ui/core/LinearProgress'

import { withStyles } from '@material-ui/core/styles'

import headerLinksStyle from '../../assets/jss/material-kit-react/components/headerLinksStyle.jsx'

import Firebase, {
  firestore,
  //siteid,
  getSurveyQuestionData,
} from '../../services/Firebase'

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {},
  tableWrapper: {
    overflowX: 'auto',
  },
  tableRowName: {
    paddingRight: 0,
  },
  tableRowNameIcon: {
    paddingRight: 16,
    color: theme.palette.primary.dark,
  },
  tableRowNameText: {
    paddingTop: 5,
  },
  notFound: {
    paddingTop: 32,
  },
  notFoundImage: {
    width: 320,
    opacity: 0.5,
  },
  tableColumnWidth: {
    width: '25%',
    maxWidth: 200,
    overflow: 'hidden',
  },
  adminNavLink: {
    color: '#000',
    textDecoration: 'none',
    '&:hover': {
      color: '#000',
    },
  },
  ...headerLinksStyle,
})
const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
})

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0)
  }

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1)
  }

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1)
  }

  handleLastPageButtonClick = event => {
    this.props.onChangePage(event, Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1))
  }

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props

    return (
      <div className={classes.root}>
        <IconButton onClick={this.handleFirstPageButtonClick} disabled={page === 0} aria-label="First Page">
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={this.handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    )
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
}

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(TablePaginationActions)

class SitesTable extends React.Component {
  state = {
    products: this.props.products,
    sites: [],
    sitesSource: [],
    ready: false,
    search: '',
    page: 0,
    rowsPerPage: 5,
    openDialog: false,
    surveyDataList: {},
    selectedValue: '',
  }

  constructor(props) {
    super(props)
    // we use this to make the card to appear after the page has been rendered
    this.viewProduct = this.viewProduct.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    //this.siteid = 'k0rKhlt7b4X6jgsyrThsE4p7vrz2'
  }

  componentDidMount() {
    // const currentUser = Firebase.auth().currentUser;
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function () {
        this.setState({ cardAnimaton: '' })
      }.bind(this),
      700
    )
    this.loadData()
    // this.getAllEventGroups();
  }
  componentWillUnmount() {
    this.unsubscribe()
    // this.unsubscribe2()
    // this.unsubscribe3()
  }

  loadData() {
    const db = firestore()
    this.unsubscribe3 = new Promise(resolve => {
      Promise.all([
        (this.unsubscribe = db
          .collection(`users`) //users id is site id
          .onSnapshot(querySnapshot => {
            let sites = []
            //let nomineesList = []
            querySnapshot.forEach(doc => {
              let user = doc.data()

              // get list of nominees using the uid from nominator
              /*
              let nominees = []
              this.unsubscribe2 = db
                .collection(`users/${this.siteid}/nominations/${nominator.uid}/nominees`)
                .onSnapshot(querySnapshot => {
                  querySnapshot.forEach(doc => {
                    let nominee = doc.data()
                    // console.log('nominee', nominee);
                    nominees.push(Object.assign({ id: doc.id }, nominee))
                    nomineesList.push(Object.assign({ id: doc.id }, nominee))
                  })
                })
              */
              // end

              sites.push(Object.assign({ id: doc.id }, user))
            })
            setTimeout(() => {
              this.setState({ sites: sites, ready: sites.length > 0 ? true : false, selectedValue: window.localStorage.getItem('siteid') })
              window.localStorage.getItem('siteid')
            }, 4000);
            /*
            setTimeout(() => {
              this.setState({
                nominators: nomineesList,
                nominatorsSource: nomineesList,
                //nominees: nomineesList,
                //nomineesSource: nomineesList,
                ready: nomineesList.length > 0 ? true : false,
              })
            }, 4000)
            */
            // console.log('nomineesList', nomineesList);
          })),
      ]).then(result => {
        resolve(result)
      })
    })
  }

  viewProduct(product) {
    this.props.viewProductCTA('paper', product)
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChange = event => {
    this.setState({ selectedValue: event.target.value })
    //console.log('set this siteid', event.target.value )
    window.localStorage.setItem('siteid', event.target.value)
    //console.log('localstorage siteid', window.localStorage.getItem('siteid'))
  }

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value })
  }

  handleDialogOpen = (scroll, data) => () => {
    delete data.id
    delete data.client_id
    delete data.siteid
    delete data.uid
    getSurveyQuestionData(this.siteid, data.survey).then(q => {
      this.setState({
        openDialog: true,
        scroll,
        surveyDataList: data,
        questions: q,
        viewEmail: data.email,
        viewUsername: data.username,
        viewLastname: data.lastname,
        viewSurvey: data.survey,
      })
    })
  }

  handleDialogClose = () => {
    this.setState({ openDialog: false })
  }


  handleSearch(search) {
    this.setState({ page: 0 }); // reset pages
    if (search) {
      this.setState({
        sites: this.state.sitesSource.filter(nominator => {

          let hit = false;
          if (nominator.nominee.toLowerCase().indexOf(search) > -1) hit = true;
          if (nominator.email.toLowerCase().indexOf(search) > -1) hit = true;
          if (nominator.name.toLowerCase().indexOf(search) > -1) hit = true;
          if (nominator.category.toLowerCase().indexOf(search) > -1) hit = true;
          if (nominator.pitch.toLowerCase().indexOf(search) > -1) hit = true;
          return hit;

        })
      });
    } else {
      this.setState({ sites: this.state.sitesSource });
    }

  }

  render() {
    const { classes } = this.props
    const { rowsPerPage, page, sites, selectedValue } = this.state
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, sites.length - page * rowsPerPage)
    //console.log('page', this.state.page);

    return this.state.ready ? (
      <div className={classes.tableWrapper}>

        <SitesTableToolbar onSearch={this.handleSearch} />
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <Hidden xsDown smDown>
                <TableCell>Email</TableCell>
              </Hidden>
              <TableCell>Site ID</TableCell>
              <TableCell>Manage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sites.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, key) => {
              return (
                <TableRow key={key}>
                  <TableCell className={classnames([classes.tableRowName, classes.tableColumnWidth])}>
                    {item.name} {item.lastname}
                  </TableCell>
                  <Hidden xsDown smDown>
                    <TableCell className={classnames([classes.tableRowName, classes.tableColumnWidth])}>
                      <Grid container direction={'row'}>
                        <Grid item className={classes.tableRowNameText}>
                          {item.email}
                        </Grid>
                      </Grid>
                    </TableCell>
                  </Hidden>
                  <TableCell className={classnames([classes.tableRowName, classes.tableColumnWidth])}>
                    {item.id}
                  </TableCell>
                  <TableCell className={classnames([classes.tableRowName, classes.tableColumnWidth])}>
                    <Radio
                      checked={selectedValue === item.id}
                      onChange={this.handleChange}
                      value={item.id}
                      name="radio-button-demo"
                      inputProps={{ 'aria-label': 'A' }}
                    />
                  </TableCell>
                  <TableCell className={classnames([classes.tableRowName, classes.tableColumnWidth])}>
                    {item.pitch}
                  </TableCell>
                  {/*
                  <TableCell>
                    <Button onClick={this.handleDialogOpen('paper', item)}>Pitch</Button>
                  </TableCell>
                  */}
                </TableRow>
              )
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 48 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={3}
                count={sites.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  native: false,
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActionsWrapped}
              />
            </TableRow>
          </TableFooter>
        </Table>

        <Dialog
          open={this.state.openDialog}
          onClose={this.handleDialogClose}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
        >
          <form className={classes.form} onSubmit={this.handleToUpdate}>
            <DialogTitle id="scroll-dialog-title">Survey Results</DialogTitle>
            <DialogContent>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <h1>{this.state.viewSurvey}</h1>
                  <h2>{this.state.viewEmail}</h2>
                  <h3>
                    {this.state.viewUsername} {this.state.viewLastname}
                  </h3>
                  <Table className={classes.table}>
                    <TableBody>
                      {Object.keys(this.state.surveyDataList).map(key => {
                        if (key === 'email' || key === 'username' || key === 'lastname' || key === 'survey') {
                          return null
                        } else {
                          return (
                            <TableRow key={key}>
                              <TableCell>{this.state.questions[key]}</TableCell>
                              <TableCell>{this.state.surveyDataList[key]}</TableCell>
                            </TableRow>
                          )
                        }
                      })}
                    </TableBody>
                  </Table>
                </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleDialogClose} color="primary">
                Discard
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    ) : (
        <LinearProgress />
      )
  }
}

SitesTable.propTypes = {
  classes: PropTypes.object.isRequired,
  sites: PropTypes.array,
  viewProductCTA: PropTypes.func,
}

export default withStyles(styles)(SitesTable)
