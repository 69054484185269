import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";

import MoreVertIcon from "@material-ui/icons/MoreVert";

import ConfirmationDialog from "../ConfirmationDialog";

import { deleteCategory } from "../../services/Firebase";

class CategoryTableMenu extends React.Component {
  state = {
    anchorEl: null,
    openConfirmation: false,
    operation: null
  };

  handleOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleDelete = () => {
    this.handleClose();
    this.setState({ openConfirmation: true, operation: "delete" });
  };

  handleEdit = () => {
    this.handleClose();
    this.props.viewCategory(this.props.category);
  };

  handleConfirm = confirmed => {
    this.setState({ openConfirmation: false });
    if (confirmed) {
      if (this.state.operation === "delete") {
        deleteCategory(
          this.props.category.client_id,
          this.props.category.id
        ).then(() => {
          //console.log("xxx redux deleted", this.props.category);
          this.props.onUpdateCategories({});
        });
      }
    }
  };

  render() {
    const { anchorEl } = this.state;
    return (
      <div>
        <IconButton
          aria-owns={anchorEl ? "simple-menu" : null}
          aria-haspopup="true"
          onClick={this.handleOpen}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleEdit}>Edit</MenuItem>
          <MenuItem onClick={this.handleDelete}>Delete</MenuItem>
        </Menu>
        <ConfirmationDialog
          open={this.state.openConfirmation}
          content={`Are you sure you want to remove ${
            this.props.category.id
          } ?`}
          onClose={this.handleConfirm}
        />
      </div>
    );
  }
}
CategoryTableMenu.propTypes = {
  classes: PropTypes.object,
  categories: PropTypes.any,
  category: PropTypes.object,
  viewCategory: PropTypes.func,
  onUpdateCategories: PropTypes.func
};

const mapStateToProps = state => ({
  categories: state.categoriesState.categories
});

const mapDispatchToProps = dispatch => ({
  onUpdateCategories: categories =>
    dispatch({ type: "CATEGORIES_SET", categories })
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CategoryTableMenu);
