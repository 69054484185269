import * as React from 'react'
import { RouteComponentProps, Link, withRouter } from 'react-router-dom'
import { Theme, makeStyles } from '@material-ui/core'

// material ui componentscomponents
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
// import LinearProgress from "@material-ui/core/LinearProgress";

// material ui icons
import AddIcon from '@material-ui/icons/Add'
import FolderIcon from '@material-ui/icons/Folder'
import PersonIcon from '@material-ui/icons/Person'
import Home from '@material-ui/icons/Home'
import Poll from '@material-ui/icons/Poll'
import Settings from '@material-ui/icons/Settings'
import ListAlt from '@material-ui/icons/ListAlt'
import InsertDriveFile from '@material-ui/icons/InsertDriveFile'
import ViewQuilt from '@material-ui/icons/ViewQuilt'
import TableChart from '@material-ui/icons/TableChart'

// components
import { UploadDialog } from '../components/'

// services
import Firebase, { getCurrentUser, useSession } from '../services/Firebase'

// @ts-ignore "fuck CSS typings"
const useStyles = makeStyles((theme: Theme) => ({
  toolbarIconButton: {
    marginLeft: -16,
    color: theme.palette.primary.dark,
  },
}))

export interface Props extends RouteComponentProps<void> {
  history: any
  location: any
  match: any
}

function LayoutDrawerImpl(props: Props) {
  const classes = useStyles()
  const user = useSession()
  const [openUpload, setOpenUpload] = React.useState(false)
  const [IsAdmin, setIsAdmin] = React.useState(false)
  const [IsBLAC, setIsBLAC] = React.useState(false)
  const [IsEcommerce, setIsEcommerce] = React.useState(false)
  const { history, location, match } = props

  React.useEffect(() => {
    // code to run on component mount
    let isSubscribed = true
    if (!!user && user.uid) {
      getCurrentUser(user.uid).then(res => {
        if (isSubscribed) {
          const authUser = res
          // how to check for admin user
          if (authUser && !!authUser.roles && !!authUser.roles.ADMIN) {
            setIsAdmin(true)
          }
          if (authUser && !!authUser.roles && !!authUser.roles.BLAC) {
            setIsBLAC(true)
          }
          if (authUser && !!authUser.roles && !!authUser.roles.ECOMMERCE) {
            setIsEcommerce(true)
          }
        }
        // this.setState({ ...res });
      })
    }
    return () => (isSubscribed = false as any)
    /*
    Firebase.auth().onAuthStateChanged(user => {
      // console.log("reloaded user", user);
      this.setState({ user: user, ready: true });

      if (!!user && user.uid)
        getCurrentUser(user.uid).then(res => {
          const authUser = res;
          // how to chack for admin user
          authUser && !!authUser.roles && !!authUser.roles.ADMIN
            ? this.setState({ IsAdmin: true })
            : null;
          this.setState({ ...res });
        });
    });
    */
  }, [])

  /*
  componentDidMount() {
    this.unregisterAuthObserver = Firebase.auth().onAuthStateChanged(user => {
      // console.log("reloaded user", user);
      this.setState({ user: user, ready: true });

      if (!!user && user.uid)
        getCurrentUser(user.uid).then(res => {
          const authUser = res;
          // how to chack for admin user
          authUser && !!authUser.roles && !!authUser.roles.ADMIN
            ? this.setState({ IsAdmin: true })
            : null;
          this.setState({ ...res });
        });
    });
  }
  */

  // componentWillUnmount() {
  //   this.unregisterAuthObserver();
  // }

  const handleUpload = () => {
    setOpenUpload(false)
  }

  /**
   * This layout menu is to be user driven. Differnt role users will get different
   * menu lists. i.e ADMIN will have extra link to admin page
   * all other user will have standard dashboard links.
   * Individual users may get cusomised links according to their role.
   */
  return (
    <div>
      <Toolbar>
        <IconButton component={Link} to="/" className={classes.toolbarIconButton}>
          <InsertDriveFile />
        </IconButton>
        <Typography variant="h6" color="inherit">
          | C | A | 
        </Typography>
      </Toolbar>
      <Divider />
      <ListItem button component={Link} to="/home">
        <ListItemIcon>
          <Home />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>
      <ListItem button component={Link} to="/account">
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="Account" />
      </ListItem>
      <ListItem button component={Link} to="/todo">
        <ListItemIcon>
          <ListAlt />
        </ListItemIcon>
        <ListItemText primary="ToDo" />
      </ListItem>
      <ListItem
        button
        onClick={() => {
          setOpenUpload(true)
        }}
      >
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText primary="Upload File" />
      </ListItem>
      <ListItem button component={Link} to="/files">
        <ListItemIcon>
          <FolderIcon />
        </ListItemIcon>
        <ListItemText primary="Files" />
      </ListItem>
      <ListItem button component={Link} to="/settings">
        <ListItemIcon>
          <Settings />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItem>
      {IsBLAC ? (
        <div>
          <Divider />
          <ListItem button component={Link} to="/polls">
            <ListItemIcon>
              <Poll />
            </ListItemIcon>
            <ListItemText primary="Polls" />
          </ListItem>
          <ListItem button component={Link} to="/nominations">
            <ListItemIcon>
              <TableChart />
            </ListItemIcon>
            <ListItemText primary="Nominations" />
          </ListItem>
        </div>
      ) : null}
      
      <Divider />

      {/* ECOMMERCE */}
      {IsEcommerce ? (
        <div>
          <ListItem button component={Link} to="/ecommerce">
            <ListItemIcon>
              <ViewQuilt />
            </ListItemIcon>
            <ListItemText primary="E-Commerce" />
          </ListItem>
        </div>
      ) : (
        <br />
      )}
      
      {IsAdmin ? (
        <div>
          <ListItem button component={Link} to="/admin">
            <ListItemIcon>
              <ViewQuilt />
            </ListItemIcon>
            <ListItemText primary="Admin" />
          </ListItem>
          <ListItem button component={Link} to="/ecommerce">
            <ListItemIcon>
              <ViewQuilt />
            </ListItemIcon>
            <ListItemText primary="E-Commerce" />
          </ListItem>
          <ListItem button component={Link} to="/bookingadmin">
            <ListItemIcon>
              <ViewQuilt />
            </ListItemIcon>
            <ListItemText primary="Booking" />
          </ListItem>
          <ListItem button component={Link} to="/surveyadmin">
            <ListItemIcon>
              <ViewQuilt />
            </ListItemIcon>
            <ListItemText primary="Forms Admin" />
          </ListItem>
          <ListItem button component={Link} to="/polls">
            <ListItemIcon>
              <ViewQuilt />
            </ListItemIcon>
            <ListItemText primary="Polls" />
          </ListItem>
          <ListItem button component={Link} to="/admin/webinar">
            <ListItemIcon>
              <ViewQuilt />
            </ListItemIcon>
            <ListItemText primary="Webinar" />
          </ListItem>
          <ListItem button component={Link} to="/admin/files">
            <ListItemIcon>
              <ViewQuilt />
            </ListItemIcon>
            <ListItemText primary="User Files" />
          </ListItem>
          <ListItem button component={Link} to="/nominations">
            <ListItemIcon>
              <TableChart />
            </ListItemIcon>
            <ListItemText primary="Nominations" />
          </ListItem>
          <ListItem button component={Link} to="/sitemanagement">
            <ListItemIcon>
              <ViewQuilt />
            </ListItemIcon>
            <ListItemText primary="Site Management" />
          </ListItem>
        </div>
      ) : (
        <br />
      )}
      <UploadDialog
        open={openUpload}
        onClose={() => handleUpload()}
        file={null}
        history={history}
        location={location}
        match={match}
      />
    </div>
  )
  // }
}

const LayoutDrawer = withRouter(LayoutDrawerImpl as any) //Note: It is a workaround not an actual solution
export default LayoutDrawer
