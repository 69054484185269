import firebase from 'firebase/app'

import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'

import { useContext } from 'react'
import { userContext } from '../contexts/User'
import { siteID } from './siteid'

const config = {
  /*
  apiKey: "AIzaSyAwy3-W3CH4VUA5WkLD7mcN3JE1JX2YicM",
  authDomain: "peachpub001.firebaseapp.com",
  databaseURL: "https://peachpub001.firebaseio.com",
  projectId: "peachpub001",
  storageBucket: "peachpub001.appspot.com",
  messagingSenderId: "1056445103179"
  */
  apiKey: 'AIzaSyDRNYhuPiy1VnG8tvXdSiUwV6eqfzMfCFM',
  authDomain: 'chatbout001.firebaseapp.com',
  databaseURL: 'https://chatbout001.firebaseio.com',
  projectId: 'chatbout001',
  storageBucket: 'chatbout001.appspot.com',
  messagingSenderId: '410612721827',
  appId: '1:410612721827:web:540d6883be019799763716',
  measurementId: 'G-7LW41B69P0',
}

firebase.initializeApp(config)

const fs = firebase.firestore()

const settings = { /* your settings... */ timestampsInSnapshots: true }
fs.settings(settings)
// const db = firebase.database();

export const firestore = () => {
  const db = firebase.firestore()
  const settings = { /* your settings... */ timestampsInSnapshots: true }
  db.settings(settings)
  return db
}

export const siteid = siteID

export const checkEcommerceFile = (siteid:any, file: any) => {
  const currentUser = firebase.auth().currentUser
  const db = firestore()
  if (currentUser !== null) {
    const filesRef = db.collection(`users/${siteid}/files`)
    const filesQuery = filesRef.where('name', '==', file.name)
    return new Promise(resolve => {
      filesQuery.get().then(querySnapshot => {
        if (querySnapshot.docs.length) {
          const doc = querySnapshot.docs[0]
          resolve(Object.assign({ id: doc.id }, doc.data()))
        } else {
          resolve(Object.assign({ error: 'file exists' }))
        }
      })
    })
  }
  return Object.assign({ error: 'something wrong' })
}
export const uploadEcommerceFile = (siteid:any, file: any) => {
  const currentUser = firebase.auth().currentUser
  if (currentUser !== null) {
    const storage = firebase.storage().ref()
    const storageRef = storage.child(`users/${siteid}/files/${file.name}`)
    const db = firestore()
    //console.log('file', file)
    return new Promise(resolve => {
      Promise.all([
        storageRef.put(file),
        db.collection(`users/${siteid}/files`).add({
          name: file.name,
          size: file.size,
          type: file.type,
          created: new Date().getTime(),
          modified: file.lastModified,
        }),
      ]).then(result => {
        resolve(result)
      })
    })
  }
  return null
}
export const overwriteEcommerceFile = (siteid:any, file: any) => {
  const currentUser = firebase.auth().currentUser
  if (currentUser !== null) {
    const storage = firebase.storage().ref()
    const storageRef = storage.child(`users/${siteid}/files/${file.name}`)
    const db = firestore()
    return new Promise(resolve => {
      Promise.all([
        storageRef.put(file),
        db.collection(`users/${siteid}/files`).add({
          name: file.name,
          size: file.size,
          type: file.type,
          created: new Date().getTime(),
          modified: file.lastModified,
        }),
        /*
        db
          .collection(`users/${currentUser.uid}/files`)
          .doc(fileExisting.id)
          .set({
            name: file.name,
            size: file.size,
            type: file.type,
            created: new Date().getTime(),
            modified: file.lastModified
          })
          */
      ]).then(result => {
        resolve(result)
      })
    })
  }
  return null
}

export const deleteEcommerceImage = (file: any) => {
  const currentUser = firebase.auth().currentUser
  if (currentUser !== null) {
    const storage = firebase.storage().ref()
    const storageRef = storage.child(`users/${currentUser.uid}/files/${file.name}`)
    const db = firestore()
    return new Promise(resolve => {
      Promise.all([
        storageRef.delete(),
        db
          .collection(`users/${currentUser.uid}/files`)
          .doc(file.id)
          .delete(),
      ]).then(result => {
        resolve(result)
      })
    })
  }
  return null
}

export const uploadEcommerceCroppedImage = (file: any, filename: any, size: any) => {
  //console.log('filename', filename)
  //console.log('file', file)
  const currentUser = firebase.auth().currentUser
  if (currentUser !== null) {
    const storage = firebase.storage().ref()
    const storageRef = storage.child(`users/${currentUser.uid}/files/${filename}`)
    const db = firestore()
    return new Promise(resolve => {
      Promise.all([
        storageRef
          .putString(file, 'base64', { contentType: 'image/png' })
          .then(snapshot => {
            //console.log('Uploaded a blob or file!')
            var downloadURL = snapshot.downloadURL
            //console.log(downloadURL)
          })
          .catch(error => {
            console.log(error)
          }),
        db.collection(`users/${currentUser.uid}/files`).add({
          name: filename,
          size: size,
          type: 'image/jpeg',
          created: new Date().getTime(),
          modified: new Date().getTime(),
        }),
      ]).then(result => {
        resolve(result)
      })
    })
  }
  return null
}

export const getFileURL = (uid: any, file: any) => {
  // const currentUser = firebase.auth().currentUser;
  const storageRef = firebase.storage().ref(`users/${uid}/files/${file}`)
  return storageRef.getDownloadURL().then(url => {
    return url
  })
}

export const checkFile = (file: any) => {
  const currentUser = firebase.auth().currentUser
  if (currentUser !== null) {
    const db = firestore()
    const filesRef = db.collection(`users/${currentUser.uid}/files`)
    const filesQuery = filesRef.where('name', '==', file.name)
    return new Promise(resolve => {
      filesQuery.get().then(querySnapshot => {
        if (querySnapshot.docs.length) {
          const doc = querySnapshot.docs[0]
          resolve(Object.assign({ id: doc.id }, doc.data()))
        } else {
          resolve(Object.assign({ error: 'file exists' }))
        }
      })
    })
  }
  return Object.assign({ error: 'something wrong' })
}

export const uploadFile = (file: any) => {
  const currentUser = firebase.auth().currentUser
  if (currentUser !== null) {
    const storage = firebase.storage().ref()
    const storageRef = storage.child(`users/${currentUser.uid}/files/${file.name}`)
    const db = firestore()
    return new Promise(resolve => {
      Promise.all([
        storageRef.put(file),
        db.collection(`users/${currentUser.uid}/files`).add({
          name: file.name,
          size: file.size,
          type: file.type,
          created: new Date().getTime(),
          modified: file.lastModified,
        }),
      ]).then(result => {
        resolve(result)
      })
    })
  }
  return Object.assign({ error: 'no file uploaded' })
}

export const overwriteFile = (file: any, fileExisting: any) => {
  const currentUser = firebase.auth().currentUser
  if (currentUser !== null) {
    const storage = firebase.storage().ref()
    const storageRef = storage.child(`users/${currentUser.uid}/files/${file.name}`)
    const db = firestore()
    return new Promise(resolve => {
      Promise.all([
        storageRef.put(file),
        db
          .collection(`users/${currentUser.uid}/files`)
          .doc(fileExisting.id)
          .set({
            name: file.name,
            size: file.size,
            type: file.type,
            created: new Date().getTime(),
            modified: file.lastModified,
          }),
      ]).then(result => {
        resolve(result)
      })
    })
  }
  return Object.assign({ error: 'no file overwrite' })
}

export const downloadFile = (file: any) => {
  const currentUser = firebase.auth().currentUser
  if (currentUser !== null) {
    const storageRef = firebase.storage().ref(`users/${currentUser.uid}/files/${file.name}`)
    storageRef.getDownloadURL().then(url => {
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', file.name)
      link.setAttribute('target', '_blank')
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }
  return null
}

export const deleteFile = (file: any) => {
  const currentUser = firebase.auth().currentUser
  if (currentUser !== null) {
    const storage = firebase.storage().ref()
    const storageRef = storage.child(`users/${currentUser.uid}/files/${file.name}`)
    const db = firestore()
    return new Promise(resolve => {
      Promise.all([
        storageRef.delete(),
        db
          .collection(`users/${currentUser.uid}/files`)
          .doc(file.id)
          .delete(),
      ]).then(result => {
        resolve(result)
      })
    })
  }
  return null
}

// Sign In
export const doSignInWithEmailAndPassword = (email: any, password: any) =>
  firebase
    .app()
    .auth()
    .signInWithEmailAndPassword(email, password)

export const doCreateUserWithEmailAndPassword = (email: any, password: any) =>
  firebase
    .app()
    .auth()
    .createUserWithEmailAndPassword(email, password)

// User API
export const doCreateUser = (id: any, username: any, lastname: any, email: any, roles: any) =>
  fs
    .collection('users')
    .doc(id)
    .set({
      id,
      username,
      lastname,
      email,
      roles,
      next_product_id: 10000,
    })

export const doUpdateUserData = (data: any) =>
  fs
    .collection('users')
    .doc(data.id)
    .update({
      ...data,
    })

export const doAddUserEcommerceData = (id: any, data: any) =>
  new Promise(resolve => {
    Promise.all([doUserNextIDIncrament(id)]).then(result => {
      const ref = fs
        .collection(`users/${id}/ecommerce`)
        .add({
          ...data,
          product_id: result[0],
        })
        .then(ref => {
          //save the id to this newly added doc
          fs.collection(`users/${id}/ecommerce`)
            .doc(ref.id)
            .update({
              id: ref.id,
            })
        })
      resolve(result)
    })
  })

export const doAddUserEcommerceCatData = async (uid: any, newcat: any) => {
  const docRef = fs.collection(`users/${uid}/ecommerce/structures/categories`).doc(newcat)
  try {
    const doc = await docRef.get()
    if (doc.exists) {
      // console.log("document exists!");
      return 'category already exists'
    } else {
      // console.log("No such document! creating it....");
      docRef.set({ subcats: [] })
      return "new category '" + newcat + "' created"
    }
  } catch (error) {
    console.log('Error getting documents: ', error)
  }
  return null
}

//////////////////////////////////////////////////////////
export const doAddUserEcommerceSubCatData = async (uid: any, category: any, newsubcat: any) => {
  const docRef = fs.collection(`users/${uid}/ecommerce/structures/categories`).doc(category)
  try {
    let doc = newsubcat
    doc = await docRef.get()
    if (doc.exists) {
      // (async (doc) => {
      const subcats = doc.data().subcats
      // let newsubcats = { ...subcats, [newsubcat]: true }; // can't be an object
      const newsubcatObj = { name: newsubcat, live: true }
      subcats.push(newsubcatObj)
      // docRef.set({ ...newsubcats });
      docRef.set({ subcats: subcats })
      return "added new subcat '" + newsubcat + "' to category: " + category
      // })();
    } else {
      // console.log("No such document! creating it....");
      // docRef.set({});
      return "new category doesn't exist yet '" + category + "'"
    }
  } catch (error) {
    console.log('Error getting documents: ', error)
  }
  return null
}

/*  // from the component ///////////////
    (async () => {
      // const compared = await compareHashPassword(pw, originPw);
      this.unregister = await doAddUserEcommerceSubCatData(
        uid,
        category,
        newsubcat
      ).then(res => {
        console.log("DONE!!!");
        this.setState({ open: true, categorymsg: res }); // snackbar message
      });
    })();
*/
//////////////////////////////////////////////////////////////

export const doUserNextIDIncrament = async (id: any) => {
  const docRef = fs.collection('users').doc(id)
  try {
    let doc = id
    doc = await docRef.get()
    if (doc.exists) {
      const data = doc.data()
      let nextId = parseInt(data.next_product_id, 10)
      nextId++
      fs.collection('users')
        .doc(data.id)
        .update({
          next_product_id: nextId,
        })
      return data.next_product_id
    } else {
      console.log('No such document!')
    }
  } catch (error) {
    console.log('Error getting documents: ', error)
  }
}

export const doUpdateUserEcommerceData = (uid: any, id: any, data: any) => {
  //console.log('xxx data', data)
  return fs
    .collection(`users/${uid}/ecommerce`)
    .doc(id)
    .update({
      ...data,
    })
}

export const deleteProduct = (uid: any, id: any) => {
  // const currentUser = firebase.auth().currentUser;
  fs.collection(`users/${uid}/ecommerce`)
    .doc(id)
    .delete()
}

export const deleteCategory = (uid: any, id: any) => {
  // const currentUser = firebase.auth().currentUser;
  return fs
    .collection(`users/${uid}/ecommerce/structures/categories`)
    .doc(id)
    .delete()
}

export const deleteSubCategory = async (category: any, subcatindex: any) => {
  const docRef = fs.collection(`users/${category.client_id}/ecommerce/structures/categories`).doc(category.id)
  try {
    let doc = category
    doc = await docRef.get()
    if (doc.exists) {
      // delete the subcat at index given
      const subcatdata = doc.data().subcats
      const oldsubcatdata = subcatdata
      subcatdata.splice(subcatindex, 1)
      docRef.set({ subcats: subcatdata })
      return 'deleted subcategory ' + oldsubcatdata[subcatindex].name
    } else {
      return 'document does not exist'
    }
  } catch (error) {
    console.log('Error getting documents: ', error)
  }
  return null
}

export const editCategoryId = async (uid: any, newcat: any, delcat: any) => {
  const docRef = fs.collection(`users/${uid}/ecommerce/structures/categories`).doc(newcat.id)
  try {
    let doc = uid
    doc = await docRef.get()
    if (doc.exists) {
      return 'category already exists'
    } else {
      docRef.set({ subcats: newcat.subcats })
      deleteCategory(uid, delcat) // todo scan all products with this category and change them
      return "renamed category '" + delcat + "' to new category '" + newcat.id + "'"
    }
  } catch (error) {
    console.log('Error getting documents: ', error)
  }
  return null
}

// send the category/subcat so we don't need to wait for it i.e doc.data()
// this might work if the hack doesn't
export const editSubCategory = async (uid: any, category: any, subcatindex: any, subcatname: any) => {
  const docRef = fs.collection(`users/${uid}/ecommerce/structures/categories`).doc(category.id)
  try {
    const doc = await docRef.get()
    if (doc.exists) {
      const oldsubcat = category.subcats[subcatindex].name
      category.subcats[subcatindex].name = subcatname
      docRef.set({ subcats: category.subcats })
      // todo scan all products with this subcategory and change them
      return "renamed subcategory '" + oldsubcat + "' to new sub-category '" + subcatname + "'"
    } else {
      return 'category does not exist'
    }
  } catch (error) {
    console.log('Error getting documents: ', error)
  }
  return null
}

// Add Orders
export const doUpdateBookingData = (uid: any, data: any) => {
  //console.log('order data', uid, data)
  return fs.collection(`users/${uid}/ecommerce/structures/orders`).add({
    ...data,
  })
}

// Get Orders
export const doGetBookingData = (uid: any, themonth: any, thedate: any) => {
  // const currentUser = firebase.auth().currentUser;
  //console.log('xyz uid', uid)
  //console.log('xyz themonth', themonth)
  //console.log('xyz thedate', thedate)
  const db = firestore()
  const orderRef = db.collection(`users/${uid}/ecommerce/structures/orders`)
  const orderQuery = orderRef.where('day', '==', thedate).where('month', '==', themonth)
  return new Promise(resolve => {
    orderQuery.get().then(querySnapshot => {
      //console.log('xyz querySnapshot', querySnapshot)
      if (querySnapshot.docs.length) {
        const orders: any = []
        querySnapshot.forEach(doc => {
          const order = doc.data()
          orders.push(Object.assign({ id: doc.id }, order))
        })
        //console.log('hits orders', orders)
        resolve(orders)
      } else {
        //console.log('hits nothing')
        resolve(null)
      }
    })
  })
}

// Get Product Name
/*
export const doGetProductName = async (uid, product_id) => {
  let docRef = fs
    .collection(`users/${uid}/ecommerce/structures/orders`)
    .where("day", "==", thedate)
    .where("month", "==", themonth);
  try {
    const doc = await docRef.get();
    console.log("hits", doc);
    if (doc.exists) {
      let orders = [];
      doc.forEach(doc => {
        let order = doc.data();
        orders.push(Object.assign({ id: doc.id }, order));
      });
      console.log("hits orders", orders);
      return orders;
    } else {
      return "no orders";
    }
  } catch (error) {
    console.log("Error getting documents: ", error);
  }
};
*/

// Password Reset
export const doPasswordReset = (email: any) => firebase.auth().sendPasswordResetEmail(email)

export const getEcomProduct = (uid: any, id: any) => {
  const db = firestore()
  const docRef = db.collection(`users/${uid}/ecommerce`).doc(id)
  return docRef
    .get()
    .then(doc => {
      if (doc.exists) {
        return doc.data()
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!')
      }
      return null
    })
    .catch(error => {
      console.log('Error getting documents: ', error)
    })
}

export const getCurrentUser = (id: any) => {
  const db = firestore()
  // let uid = firebase.auth().currentUser.uid;
  const docRef = db.collection('users').doc(id)
  return docRef
    .get()
    .then(doc => {
      if (doc.exists) {
        return doc.data()
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!')
      }
      return null
    })
    .catch(error => {
      console.log('Error getting documents: ', error)
    })
}

export const getCurrentUserEcom = (id: any) => {
  const db = firestore()
  return (
    db
      .collection(`users/${id}/ecommerce`)
      // .orderBy("created", "desc")
      .onSnapshot(querySnapshot => {
        const products: any = []
        querySnapshot.forEach(doc => {
          const product = doc.data()
          products.push(Object.assign({ id: doc.id }, product))
        })
      })
  )
}

export const onAuthUserListener = (next: any, fallback: any) =>
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
      user(authUser.uid)
        .get()
        .then(snapshot => {
          let dbUser: any
          dbUser = snapshot.data()

          // default empty roles
          if (!dbUser.roles) {
            dbUser.roles = {}
          }

          // merge auth and db user
          const authUserNext = {
            uid: authUser.uid,
            email: authUser.email,
            emailVerified: authUser.emailVerified,
            providerData: authUser.providerData,
            ...dbUser,
          }

          next(authUserNext)
        })
    } else {
      fallback()
    }
  })

const user = (uid: any) => fs.doc(`users/${uid}`)
export const theuser = (uid: any) => fs.doc(`users/${uid}`)
export const doSendEmailVerification = (currentUser: any) => {
  //console.log('currentUser', currentUser)
  currentUser.user.sendEmailVerification({
    url: 'http://localhost:3000',
  })
}

// const users = () => db.ref("users");

export const onAuthUserListenerFS = (next: any, fallback: any) =>
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) {
      userFS(authUser.uid)
        .get()
        .then(snapshot => {
          const dbUser = snapshot.data()

          // default empty roles
          if (dbUser && !dbUser.roles) {
            dbUser.roles = {}
          }

          // merge auth and db user
          const authUserNext = {
            uid: authUser.uid,
            email: authUser.email,
            emailVerified: authUser.emailVerified,
            providerData: authUser.providerData,
            ...dbUser,
          }

          next(authUserNext)
        })
    } else {
      fallback()
    }
  })

// *** User API ***

export const userFS = (uid: any) => fs.doc(`users/${uid}`)

export const usersFS = () => fs.collection('users')

export const useSession = () => {
  const { user } = useContext(userContext)
  return user
}

// *** Survey API ***

/**
 * if an 'id' exists in the data then update this
 * document otherwise add a new document
 *
 * @param siteid
 * @param data
 */
export const saveSurvey = (siteid: any, data: any) => {
  const currentUser = firebase.auth().currentUser
  if (currentUser !== null) {
    if (data.id) {
      return fs
        .collection(`users/${currentUser.uid}/survey`)
        .doc(data.id)
        .update({
          ...data,
        })
    } else {
      return fs.collection(`users/${currentUser.uid}/survey`).add({
        ...data,
      })
    }
  }
}

export const getSurveyData = (siteid: any, uid: any, survey: any) => {
  const db = firestore()
  const currentUser = firebase.auth().currentUser
  if (currentUser !== null) {
    const surveyRef = db.collection(`users/${currentUser.uid}/survey`)
    const surveyQuery = surveyRef.where('uid', '==', uid).where('survey', '==', survey)
    return new Promise(resolve => {
      surveyQuery.get().then(querySnapshot => {
        if (querySnapshot.docs.length) {
          const surveys: any = []
          querySnapshot.forEach(doc => {
            const survey = doc.data()
            surveys.push(Object.assign({ id: doc.id }, survey))
          })
          resolve(surveys)
        } else {
          resolve(null)
        }
      })
    })
  }
}

export const getSurveyQuestionData = (siteid: any, survey: any) => {
  //console.log('survey', survey)
  const currentUser = firebase.auth().currentUser
  if (currentUser !== null) {
    const docRef = fs.collection(`users/${currentUser.uid}/surveyQuestions`).doc(survey)
    return docRef
      .get()
      .then(doc => {
        if (doc.exists) {
          return doc.data()
        } else {
          console.log('No such document!')
        }
        return null
      })
      .catch(error => {
        console.log('Error getting documents: ', error)
      })
  }
}

export const gcfn = firebase.functions()

export default firebase
